import React, { useState } from "react";
import { Form, Input, Button, Row, Col } from "antd";

function AddRole({ form, onFinish }) {
  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          {/* Left Side Form Fields */}
          <Col span={24}>
            <Form.Item
              name="name"
              label={
                <span className="text-lightGrey font-medium">Role Name</span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter Role Name"
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Submit Button */}
        <div className="flex items-center justify-center gap-3 mt-7">
          <Button
            type="primary"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none block py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AddRole;
