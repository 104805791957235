import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, Row, Col, message, Select } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import CustomSelect from "../customSelect/indes";
import { getUser } from "../../../services/auth";
import { ServerError } from "../notification";
import { getAllLanguages } from "../../../services/globalService";
import { dropDownPayload } from "../../../utils/helper";
import { API_BASE_URL } from "../../shared/contants";

function ProfilePicture({ form, onFinish }) {
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [language, setLanguage] = useState([]);

  const [loading, setLoading] = useState(false);

  const props = {
    name: "file",
    action: `${API_BASE_URL}/file/multi/user`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        form.setFieldsValue({
          avatar: info.file.response.data.file,
        });
        setUploadedUrl(info.file.response.data.file);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  const getLanguage = async () => {
    try {
      const { data: response } = await getAllLanguages();
      const { data: record } = response;
      setLanguage(record.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getUserDatails = async () => {
    try {
      const { data: response } = await getUser();
      const { data: record } = response;
      form.setFieldsValue(record);
      setUploadedUrl(record.avatar);
      setUserDetails(record);
    } catch {
      return ServerError();
    }
  };

  useEffect(() => {
    getUserDatails();
    getLanguage();
  }, []);

  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          {/* Left Side Form Fields */}
          <Col span={16}>
            <Form.Item
              name="firstName"
              label={
                <span className="text-lightGrey font-medium">First Name</span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter First Name"
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              label={
                <span className="text-lightGrey font-medium">Last Name</span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter Last Name"
              />
            </Form.Item>
            <Form.Item
              name="email"
              label={<span className="text-lightGrey font-medium">Email</span>}
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                type="email"
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter Email"
              />
            </Form.Item>
            <Form.Item
              name="contactNo"
              label={
                <span className="text-lightGrey font-medium">
                  Contact Number
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter Contact Number"
              />
            </Form.Item>
            {/* Parent Type and Display Order */}
            <Form.Item
              name="languageId"
              label={
                <span className="text-lightGrey font-medium">
                  Select Language{" "}
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <CustomSelect
                options={dropDownPayload(language)}
                placeholder={"Enter Language"}
              />
            </Form.Item>
          </Col>

          {/* Right Side Image Upload */}
          <Col span={8}>
            <Form.Item
              name="avatar"
              label="Proofile Image"
              rules={[{ required: true, message: "This is required" }]}
            >
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                {...props}
              >
                {uploadedUrl ? (
                  <img
                    src={uploadedUrl}
                    alt="avatar"
                    style={{ width: "100%" }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        {/* Submit Button */}
        <div className="flex items-center justify-center gap-3 mt-7">
          <Button
            type="primary"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none block py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
}

export default ProfilePicture;
