import Api from "../../../network/client";

export const changeRoleStatus = async (id, data) => {
  return Api.put(`role/${id}`, data);
};
export const createRole = async (data) => {
  return Api.post(`role`, data);
};
export const updateRole = async (data, id) => {
  return Api.put(`role/${id}`, data);
};
export const getAllRoleApi = async () => {
  return Api.get(`role`);
};
export const deleteRoleApi = async (id) => {
  return Api.delete(`role/${id}`);
};

export const getAllRolePermissionApi = async (roleId) => {
  return Api.get(`role/${roleId}/permissions`);
};
export const updateRolePermissionApi = async (payload, roleId) => {
  return Api.post(`role/${roleId}/permissions`, payload);
};
