import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, message } from "antd";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { getData, submitContent } from "./service";
import CustomModal from "../../../../shared/modal";
import { API_BASE_URL } from "../../../../shared/contants";

function Testimonials() {
  const [form] = Form.useForm();
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [content, setContent] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function deleteMatchingRecord(obj, target) {
    // Destructure the values to access value.data
    const { value } = obj;

    // Use the filter method to keep only the records that don't match the target object
    value.data = value.data.filter(
      (item) => item.title !== target.title || item.name !== target.name,
    );

    return obj;
  }

  const onFinish = async (values) => {
    values.image = uploadedUrl;
    let tempArr = content.value.data || [];
    let tempContent = content;
    tempArr.push(values);
    tempContent.value.data = tempArr;

    const payload = {
      value: {
        ...tempContent.value,
      },
    };

    try {
      const response = await submitContent(content._id, payload);
      if (response?.status == 200) {
        closeModal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getContent = async () => {
    const response = await getData();
    const {
      data: { data },
    } = response;
    setContent(data.data[0]);
    form.setFieldsValue(data.data[0].value);
  };

  useEffect(() => {
    getContent();
  }, [isModalOpen, refresh]);
  // File upload props
  const props = {
    name: "file",
    action: `${API_BASE_URL}/file/multi/landing_testimonial`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setUploadedUrl(info.file.response.data.file);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const onClickDelete = async (record) => {
    setRefresh(true);
    try {
      const response = await submitContent(
        content._id,
        deleteMatchingRecord(content, record),
      );
      if (response.status == 201 || response.status == 201) {
        setRefresh(false);
        message.success(`Successfully Deleted`);
      }
    } catch (err) {
      message.success(`Error occured`);
      console.log(err);
    }
  };

  const onClickAdd = () => {
    openModal();
    setUploadedUrl(null);
    form.resetFields();
  };

  return (
    <>
      <div className="bg-white p-6 rounded-2xl">
        <div className="flex items-center justify-between border-b pb-2.5 mb-8">
          <h3 className="text-ufoGreen text-xl font-medium flex justify-start flex-1">
            Testimonials
          </h3>

          <button
            id="addSeasonBtn"
            onClick={onClickAdd}
            className="block capitalize py-4 px-8 rounded-full leading-none text-bold bg-redOrange text-white"
          >
            Add Testimonial
          </button>
          <CustomModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            heading={"Add Testimonial"}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}
              className="mt-5"
            >
              <Form.Item
                label={
                  <div className="text-black text-md ml-2 font-medium">
                    Title
                  </div>
                }
                name="title"
                rules={[{ required: true, message: "Please input the Title!" }]}
              >
                <Input
                  placeholder="Enter Title"
                  className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full mt-2 outline-0"
                />
              </Form.Item>

              {/* Name Field */}
              <Form.Item
                label={
                  <div className="text-black text-md ml-2 font-medium">
                    Name
                  </div>
                }
                name="name"
                rules={[{ required: true, message: "Please input the Name!" }]}
              >
                <Input
                  placeholder="Enter Name"
                  className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full mt-2 outline-0"
                />
              </Form.Item>

              {/* Description Field */}
              <Form.Item
                label={
                  <div className="text-black text-md ml-2 font-medium">
                    Description
                  </div>
                }
                name="description"
                rules={[
                  { required: true, message: "Please input the Description!" },
                ]}
              >
                <Input.TextArea
                  placeholder="Enter Description"
                  rows={4}
                  className="block py-3 px-6 border border-lightGrey w-full rounded-lg mt-2 outline-0"
                />
              </Form.Item>

              <Form.Item label="Upload Image" name="image">
                {uploadedUrl && (
                  <img
                    src={uploadedUrl}
                    class="rounded-full w-[300px] h-[300px]"
                  />
                )}
                <Upload {...props}>
                  <Button
                    icon={<UploadOutlined />}
                    className="flex p-3 px-8 items-center justify-start bg-redOrange font-medium text-md h-auto text-white"
                  >
                    Click to Upload
                  </Button>
                </Upload>
              </Form.Item>

              <Form.Item>
                <div className="flex justify-end gap-3">
                  <Button
                    className="block py-4 h-auto leading-none px-10 bg-veryLightGrey rounded-full text-lightGrey font-medium border border-lightGrey"
                    onClick={() => form.resetFields()}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="block py-4 h-auto leading-none px-12 bg-redOrange rounded-full text-white font-medium border border-redOrange"
                  >
                    Save
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </CustomModal>
        </div>

        <div className="flex flex-col justify-between min-h-80">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th className="py-4 border-b-[0.1rem] text-left">
                  <div className="flex items-center justify-start">
                    <span className="text-lg">Image</span>
                  </div>
                </th>
                <th className="py-4 border-b-[0.1rem] text-left min-w-28">
                  <span className="text-lg">Title</span>
                </th>
                <th className="py-4 border-b-[0.1rem] text-left">
                  <span className="text-lg">Name</span>
                </th>
                <th className="py-4 border-b-[0.1rem] text-left">
                  <span className="text-lg">Description</span>
                </th>
                <th className="py-4 border-b-[0.1rem] text-right">
                  <span className="text-lg">Action</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {content?.value?.data &&
                content?.value?.data?.map((record) => {
                  return (
                    <tr>
                      <td className="py-4 border-b-[0.1rem] text-left">
                        <span className="text-lg font-medium">
                          <img
                            class="rounded-full w-[50px] h-[50px]"
                            src={record.image}
                          />
                        </span>
                      </td>
                      <td className="py-4 border-b-[0.1rem] text-left">
                        <span className="text-lg font-medium">
                          {record.title}
                        </span>
                      </td>
                      <td className="py-4 border-b-[0.1rem] text-left">
                        <span className="text-lg font-medium">
                          {record.name}
                        </span>
                      </td>
                      <td className="py-4 border-b-[0.1rem] text-left">
                        <span className="text-lg font-medium">
                          {record.description}
                        </span>
                      </td>
                      <td className="py-4 border-b-[0.1rem] text-right px-4">
                        <label className="inline-flex items-center align-sub justify-end cursor-pointer relative p-2">
                          {/* <EditOutlined className='' /> */}
                        </label>
                        <label
                          onClick={() => onClickDelete(record)}
                          className="inline-flex items-center align-sub justify-end cursor-pointer relative"
                        >
                          <DeleteOutlined />
                        </label>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Testimonials;
