import React from "react";
import { Form, Input } from "antd";
import { confirmPassword, submitLogin } from "./service";
import { useNavigate, useParams } from "react-router-dom";
import { Authenticate } from "../../../redux/feature/authSlice";
import { useDispatch } from "react-redux";

function ResetPassword() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  const onFinish = async (values) => {
    try {
      const payload = {
        token: id,
        ...values,
      };
      const response = await confirmPassword(payload);
      if (response?.status == 201 || response?.status == 200) {
        localStorage.setItem("accessToken", response.data.data.accessToken);
        dispatch(Authenticate(response.data.data));
        navigate("../");
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="relative flex items-center justify-center h-full py-10">
      <div className="flex-1 flex items-center h-full border-r-2 border-lightGrey">
        <img
          src="/img/login.svg"
          alt=""
          width=""
          height=""
          className="block mx-auto w-[32rem]"
        />
      </div>
      <div className="flex-1 flex items-center h-full flex-col">
        <div className="flex-1 flex items-center justify-center w-[30rem] mx-auto">
          <div className="w-full">
            <h2 className="text-2xl font-medium mb-5">Reset your password</h2>
            <Form
              name="login-form"
              onFinish={onFinish}
              form={form}
              layout="vertical"
              className="w-full"
            >
              <div className="relative mb-5">
                <Form.Item
                  name="password"
                  rules={[{ required: true, message: "Password is required" }]}
                  hasFeedback
                >
                  <Input
                    type="password"
                    className="w-full border focus:border-ufoGreen rounded-full py-3 px-6 flex"
                    placeholder="Enter new password"
                  />
                </Form.Item>
              </div>
              <div className="relative mb-5">
                <Form.Item
                  hasFeedback
                  dependencies={["password"]}
                  name="confirmPassword"
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The new password that you entered do not match!",
                          ),
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    type="password"
                    className="w-full border focus:border-ufoGreen rounded-full py-3 px-6 flex"
                    placeholder="Enter new Password"
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <input
                  type="submit"
                  defaultValue="Login"
                  className="block cursor-pointer bg-redOrange w-full p-4 rounded-full mt-5 text-white transition ease-in-out hover:bg-ufoGreen font-medium"
                />
              </Form.Item>
            </Form>
          </div>
        </div>
        <ul className="flex items-center justify-center text-lightGrey">
          <li className="relative first:before:hidden before:absolute before:block before:w-1 before:aspect-square before:rounded-full before:bg-lightGrey before:top-3 before:-left-0.5 px-4">
            <a href="#">Terms of use</a>
          </li>
          <li className="relative first:before:hidden before:absolute before:block before:w-1 before:aspect-square before:rounded-full before:bg-lightGrey before:top-3 before:-left-0.5 px-4">
            <a href="#">Privacy Policy</a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ResetPassword;
