import React, { useEffect } from "react";
import { Form, Input, Button, Row, Col, Checkbox, Collapse } from "antd";
import { useState } from "react";
const { Panel } = Collapse;

function AddPermisions({ form, onFinish, permissions }) {
  const [open, setOpen] = useState(["1"]);

  useEffect(() => {
    let checkedPermissions = [];
    Object.keys(permissions).forEach((key) => {
      checkedPermissions = checkedPermissions.concat(
        permissions[key].filter((permission) => permission.checked),
      );
    });
    const checkedPermissionIds = checkedPermissions.map(
      (permission) => permission.permissionId,
    );
    form.setFieldsValue({
      permissions: checkedPermissionIds,
    });
  }, [permissions, form]);
  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="permissions">
          <Checkbox.Group className="permission-group">
            <Collapse
              size="large"
              expandIconPosition="end"
              className="permission-collapse"
              defaultActiveKey="Role"
            >
              {Object.keys(permissions).map((permKey) => (
                <Panel header={permKey} key={permKey}>
                  <div className="permission-checkbox-main">
                    {Object.values(permissions[permKey]).map((permission) => (
                      <label className="permission-checkbox">
                        <Checkbox value={permission.permissionId} />
                        <span>{permission.value}</span>
                      </label>
                    ))}
                  </div>
                </Panel>
              ))}
            </Collapse>
          </Checkbox.Group>
        </Form.Item>
        {/* <Row gutter={16}>
          <Col span={24}>
            <span className="text-lightGrey font-medium mb-5">Permissions</span>
            <Form.Item name="permissions">
              <Checkbox.Group>
                {Object.keys(permissions).map((permKey) =>
                  Object.values(permissions[permKey]).map((permission) => (
                    <Row key={permission.permissionId} gutter={16}>
                      <Col span={24}>
                        <label>
                          <Checkbox value={permission.permissionId} />
                          <span>{permission.value}</span>
                        </label>
                      </Col>
                    </Row>
                  )),
                )}
              </Checkbox.Group>
            </Form.Item>
          </Col>
        </Row> */}
        {/* Submit Button */}
        <div className="flex items-center justify-end gap-3 mt-7">
          <Button
            type="primary"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium"
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AddPermisions;
