import React, { useState } from "react";
import CustomTable from "../../component/shared/customTable";
import { Link, useNavigate } from "react-router-dom";
import { deletePurchaseById, updatePurchase } from "../../services/purchase";
import { message } from "antd";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";

function Purchase() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const columns = [
    {
      title: "PurchaseI ID",
      dataIndex: "purchaseId",
      key: "purchaseId",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Sub Total",
      dataIndex: "subTotal",
      key: "subTotal",
    },
    {
      title: "Remaining Balance",
      dataIndex: "remainingBalance",
      key: "remainingBalance",
    },
  ];

  const onClickEdit = (row) => {
    navigate("../purchase/add-purchase", { state: { id: row._id } });
  };

  const onClickDelete = async (row) => {
    try {
      const { data } = await deletePurchaseById(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onClickStatus = async (row) => {
    const payload = {
      ...row,
      isActive: !row.isActive,
    };
    try {
      const response = await updatePurchase(payload, row._id);
      if (
        response.data.meta.status == 201 ||
        response.data.meta.status == 200
      ) {
        message.success(`Successfully Updated`);
        setRefreshTable((prev) => !prev);
      }
    } catch (err) {
      message.success(`Error occured`);
      console.log(err);
    }
  };
  return (
    <main className="mt-6">
      <div className="mt-8 px-6 py-8 bg-white rounded-3xl">
        <div className="flex items-center justify-between mb-8">
          <h2 className="text-2xl font-medium">Purchase</h2>
          <div className="flex items-center justify-end gap-6">
            <Link to="/purchase/add-purchase">
              <a
                href="#"
                className="block capitalize py-4 px-6 min-w-36 text-center rounded-full leading-none text-bold bg-redOrange text-white"
              >
                Add Purchase
              </a>
            </Link>
          </div>
        </div>
        <form action="" className="relative mb-6">
          <input
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Find Purchase by name, barcode"
            className="block leading-none py-3 pr-10 pl-7 border w-full rounded-full"
          />
        </form>
        {/* Records */}
        <div className="flex flex-col justify-between min-h-96">
          <CustomTable
            columns={columns}
            endPoint={"purchase"}
            search={searchText}
            handleEdit={onClickEdit}
            handleStatus={onClickStatus}
            handleDelete={onClickDelete}
            showActions={true}
            refreshTable={refreshTable}
          />
        </div>
      </div>
    </main>
  );
}

export default Purchase;
