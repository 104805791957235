import React, { useEffect, useState } from "react";
import { Form, Input, message } from "antd";
import { getData, submitContent } from "./service";

function Pricing() {
  const [form] = Form.useForm();
  const [content, setContent] = useState({});

  const onFinish = async (values) => {
    const payload = {
      value: {
        ...values,
        _id: content.value.id,
      },
    };
    try {
      const response = await submitContent(content._id, payload);
      if (response?.status == 200) {
        message.success("Content Published");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getContent = async () => {
    const response = await getData();
    const {
      data: { data },
    } = response;
    setContent(data.data[0]);
    form.setFieldsValue(data.data[0].value);
  };

  useEffect(() => {
    getContent();
  }, []);
  return (
    <>
      <div className="bg-white p-6 rounded-2xl">
        <h3 className="text-ufoGreen text-xl font-medium border-b pb-2.5 flex justify-start">
          Pricing
        </h3>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="mt-5"
        >
          <Form.Item
            label={
              <div className={"text-black text-md ml-2 font-medium"}>
                Section Tagline{" "}
              </div>
            }
            name="tagLine"
            rules={[{ required: true, message: "Please input the Tagline!" }]}
          >
            <Input
              placeholder="Hero Tagline"
              className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full mt-2 outline-0"
            />
          </Form.Item>

          <Form.Item
            label={
              <div className={"text-black text-md ml-2 font-medium"}>
                Section Title
              </div>
            }
            name="title"
            rules={[{ required: true, message: "Please input the Title!" }]}
          >
            <Input
              placeholder="Hero Title"
              className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full mt-2 outline-0"
            />
          </Form.Item>

          <Form.Item>
            <div className="flex justify-end gap-3">
              <input
                type="submit"
                defaultValue="Save"
                className="cursor-pointer leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium border border-redOrange"
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default Pricing;
