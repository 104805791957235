import React from "react";

function SaleSummary({ registerData }) {
  const { transaction } = registerData || {};

  return (
    <>
      <table className="w-full">
        <tbody>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              Total Sales
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {transaction?.total || 0}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              On Account Sale
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {transaction?.onAccountSale || 0}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              Item Discounts
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {transaction?.itemDiscount || 0}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              Order Discounts
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {transaction?.orderDiscount || 0}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              Surcharge
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {transaction?.surcharge || 0}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              Avg Sale Volume
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {transaction?.avgSalesVolume || 0}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              No Of Transactions
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {transaction?.totalTransactions || 0}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              New Customers
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {transaction?.newCustomer || 0}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default SaleSummary;
