import { Form, Input, Select, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getAllTaxApi } from "../../services/product/tax";
import CustomSelect from "../shared/customSelect/indes";
import { dropDownPayloadLov } from "../../utils/helper";
import { getAllListOfValueByKey } from "../../services/globalService";

function PurchasePricing({ form }) {
  const [tax, setTax] = useState([]);
  const [discountType, setDiscountType] = useState([]);
  const [retailPrice, setRetailPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState("");

  const { Option } = Select;
  const getAllTax = async () => {
    try {
      const response = await getAllTaxApi("tax");
      setTax(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllTax();
  }, []);

  const getListOfValues = async () => {
    try {
      const response = await getAllListOfValueByKey("discount_type");
      setDiscountType(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getListOfValues();
    calculateTotalPriceEx();
  }, []);

  const getTaxRate = (taxId) => {
    const tempTax = tax.find((data) => data._id == taxId);
    return tempTax?.rate || 0;
  };
  const OnchangeDiscountType = (value, name) => {
    const values = form.getFieldsValue();
    const compositeProduct = values.compositeProducts[name];
    const discountTypeId = compositeProduct.discountTypeId;
    // Update the form with the calculated retail price including tax
    const disCountTypeKey = discountType.find((data) => data._id == value);
    form.setFieldsValue({
      compositeProducts: {
        ...values.compositeProducts,
        [name]: {
          ...compositeProduct,
          discountTypeId: value,
          disCountTypeKey: disCountTypeKey.key,
        },
      },
    });
    handlePriceChange(name);
  };
  const handlePriceChange = (name) => {
    const values = form.getFieldsValue();
    const compositeProduct = values.compositeProducts[name];
    const quantity = Number(compositeProduct.quantity) || 0;

    form.setFieldsValue({
      compositeProducts: {
        [name]: {
          ...compositeProduct,
          quantity: quantity,
        },
      },
    });
    calculateTotalPriceEx();
    const taxID = form.getFieldsValue().taxId;
    if (taxID) {
      onChangeTax(taxID);
    }
  };

  useEffect(() => {
    calculateTotalPriceEx();
  }, [form]);

  const calculateTotalPriceEx = () => {
    const values = form?.getFieldsValue() || {};

    const compositeProduct = Array.isArray(values.compositeProducts)
      ? values.compositeProducts
      : [];

    const retailPrice = compositeProduct.reduce((acc, product) => {
      const price = Number(product?.retailPriceInclTax || 0);
      const quantity = Number(product?.quantity || 0);
      return acc + price * quantity;
    }, 0);
    form.setFieldValue("retailPrice", retailPrice);
    setRetailPrice(retailPrice);
  };

  const onChangeTotalPrice = (text) => {
    setTotalPrice(text);
  };

  const onChangeTax = (id) => {
    const taxValue = tax.find((item) => item._id === id);
    const taxDecimal = Number(taxValue.rate) / 100; // Convert to decimal
    const taxAmount = Number(retailPrice) * taxDecimal;
    const totalPrice = retailPrice + taxAmount;
    form.setFieldValue("retailPriceInclTax", totalPrice);
  };
  return (
    <div className="flex flex-col gap-6 mt-6">
      <Form.List name="compositeProducts">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div className="flex gap-4 items-start" key={key}>
                {/* <Form.Item
                  {...restField}
                  name={[name, "costPrice"]}
                  fieldKey={[fieldKey, "costPrice"]}
                  label="Cost Price"
                  rules={[
                    { required: true, message: "Please enter cost price" },
                  ]}
                  className="flex-1"
                >
                  <Input
                    disabled
                    onChange={(e) => handlePriceChange(name)}
                    type="number"
                    placeholder="Rs.0.00"
                    className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                  />
                </Form.Item> */}

                {/* Discount */}
                <Form.Item
                  {...restField}
                  name={[name, "discount"]}
                  fieldKey={[fieldKey, "discount"]}
                  label="Discount"
                  className="flex-1"
                >
                  <Input
                    onChange={(e) => handlePriceChange(name)}
                    type="number"
                    // disabled
                    defaultValue={"0.0"}
                    className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                  />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "discountTypeId"]}
                  fieldKey={[fieldKey, "taxId"]}
                  label="Discount Type"
                  className="flex-1"
                  disabled
                >
                  <CustomSelect
                    disabled
                    onChange={(value) => OnchangeDiscountType(value, name)}
                    options={dropDownPayloadLov(discountType)}
                  />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "retailPrice"]}
                  fieldKey={[fieldKey, "retailPrice"]}
                  label="Retail Price (Ex. Tax)"
                  disabled
                  rules={[
                    { required: true, message: "Please enter retail price" },
                  ]}
                  className="flex-1"
                >
                  <Input
                    onChange={(e) => handlePriceChange(name)}
                    disabled
                    placeholder="Rs.0.00"
                    type="number"
                    className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                  />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "taxId"]}
                  fieldKey={[fieldKey, "taxId"]}
                  label="Tax Rate"
                  className="flex-1"
                >
                  <Select
                    disabled
                    placeholder="Tax"
                    className="h-auto"
                    onChange={(value) => handlePriceChange(name)}
                  >
                    {tax.map((data, key) => {
                      return (
                        <Option key={key} value={data._id}>
                          {data.name} {data.rate}%
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "retailPriceInclTax"]}
                  fieldKey={[fieldKey, "retailPriceInclTax"]}
                  label="Retail Price (Inc. Tax)"
                  rules={[
                    {
                      required: true,
                      message: "Please enter retail price (Inc. Tax)",
                    },
                  ]}
                  className="flex-1"
                >
                  <Input
                    onChange={(e) => handlePriceChange(name)}
                    placeholder="Rs.0.00"
                    type="number"
                    disabled
                    className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                  />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "quantity"]}
                  fieldKey={[fieldKey, "quantity"]}
                  label="Quantity"
                  rules={[
                    {
                      required: true,
                      message: "Please enter quantity",
                    },
                  ]}
                  className="flex-1"
                >
                  <Input
                    onChange={(e) => handlePriceChange(name)}
                    placeholder="0.00"
                    type="number"
                    className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                  />
                </Form.Item>
              </div>
            ))}
          </>
        )}
      </Form.List>

      <Form.Item
        name="retailPrice"
        rules={[{ required: true, message: "Please input the Price!" }]}
        label="Composite/bundle selling price. (Ex. Tax)"
        className="flex-1 mb-0"
      >
        <Input
          disabled
          placeholder="RS 0.0"
          className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
        />
      </Form.Item>

      <Form.Item
        name={"taxId"}
        label="Tax Rate"
        className="flex-1 mb-0"
        rules={[{ required: true, message: "Please input the tax!" }]}
      >
        <Select
          placeholder="Tax"
          className="h-auto mt-0"
          onChange={onChangeTax}
        >
          {tax.map((data, key) => {
            return (
              <Option key={key} value={data._id}>
                {data.name} {data.rate}%
              </Option>
            );
          })}
        </Select>
      </Form.Item>

      <Form.Item
        name="retailPriceInclTax"
        label="Composite/bundle selling price. (Inc. Tax)"
        rules={[{ required: true, message: "Please input the Price!" }]}
      >
        <Input
          value={retailPrice}
          onChange={(e) => onChangeTotalPrice(e.target.value)}
          placeholder="RS 0.0"
          // type="number"
          className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
        />
      </Form.Item>
    </div>
  );
}

export default PurchasePricing;
