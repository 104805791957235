import React from "react";

function BasicCard({
  heading,
  children,
  buttonTxt,
  onClickBtn,
  className = "",
}) {
  return (
    <div className={`bg-white rounded-3xl py-5 px-6 mt-5 ${className}`}>
      <div className="flex justify-between items-center border-b pb-2.5">
        <h3 className="text-ufoGreen text-xl font-medium">{heading}</h3>
        {buttonTxt?.length && (
          <a
            onClick={onClickBtn}
            className="bg-redOrange text-white px-4 py-2 rounded-full"
          >
            {buttonTxt}
          </a>
        )}
      </div>
      {children}
    </div>
  );
}

export default BasicCard;
