import { Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getAllTaxApi } from "../../../services/product/tax";
import CustomSelect from "../../shared/customSelect/indes";
import { dropDownPayloadLov, formatNames } from "../../../utils/helper";
import { getAllListOfValueByKey } from "../../../services/globalService";

function Pricing({ form }) {
  const [tax, setTax] = useState([]);
  const [discountType, setDiscountType] = useState([]);
  const { Option } = Select;
  const getAllTax = async () => {
    try {
      const response = await getAllTaxApi("tax");
      setTax(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllTax();
  }, []);

  const getListOfValues = async () => {
    try {
      const response = await getAllListOfValueByKey("discount_type");
      setDiscountType(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getListOfValues();
  }, []);

  const getTaxRate = (taxId) => {
    const tempTax = tax.find((data) => data._id == taxId);
    return tempTax?.rate || 0;
  };
  const OnchangeDiscountType = (value, name) => {
    const values = form.getFieldsValue();
    const variant = values.variants[name];
    const discountTypeId = variant.discountTypeId;
    // Update the form with the calculated retail price including tax
    const disCountTypeKey = discountType.find((data) => data._id == value);
    form.setFieldsValue({
      variants: {
        ...values.variants,
        [name]: {
          ...variant,
          discountTypeId: value,
          disCountTypeKey: disCountTypeKey.key,
        },
      },
    });
    handlePriceChange(name);
  };
  const handlePriceChange = (name) => {
    const values = form.getFieldsValue();
    const variant = values.variants[name];
    const costPrice = parseFloat(variant.costPrice) || 0;
    const discount = parseFloat(variant.discount) || 0;
    const taxId = variant.taxId;
    const taxRate = getTaxRate(taxId); // Get the tax rate in percentage
    const disCountTypeKey = variant.disCountTypeKey;
    // Calculate the tax amount
    const taxAmount = (costPrice * taxRate) / 100;
    const discountAmount =
      disCountTypeKey == "percentage" ? (costPrice * discount) / 100 : discount;

    let retailPriceInclTax, retailPrice;
    retailPrice = costPrice - discountAmount;
    retailPriceInclTax = costPrice + taxAmount - discountAmount;

    // Update the form with the calculated retail price including tax
    form.setFieldsValue({
      variants: {
        ...values.variants,
        [name]: {
          ...variant,
          retailPriceInclTax: retailPriceInclTax.toFixed(2),
          retailPrice: retailPrice.toFixed(2),
        },
      },
    });
  };
  return (
    <div className="addProductPricing flex flex-col gap-6 mt-6">
      <Form.List name="variants">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div className="row">
                <div className="flex gap-4 items-start">
                  {formatNames(
                    form.getFieldsValue()?.variants &&
                      form.getFieldsValue()?.variants[name]?.combination,
                  )}
                </div>
                <div className="flex gap-4 items-start" key={key}>
                  <Form.Item
                    {...restField}
                    name={[name, "costPrice"]}
                    fieldKey={[fieldKey, "costPrice"]}
                    label="Cost Price"
                    rules={[
                      { required: true, message: "Please enter cost price" },
                    ]}
                    className="flex-1"
                  >
                    <Input
                      onChange={(e) => handlePriceChange(name)}
                      type="number"
                      placeholder="Rs.0.00"
                      className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                    />
                  </Form.Item>

                  {/* Discount */}
                  <Form.Item
                    {...restField}
                    name={[name, "discount"]}
                    fieldKey={[fieldKey, "discount"]}
                    label="Discount"
                    className="flex-1"
                  >
                    <Input
                      onChange={(e) => handlePriceChange(name)}
                      type="number"
                      defaultValue={"0.0"}
                      className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                    />
                  </Form.Item>

                  {/* Discount Type */}
                  <Form.Item
                    {...restField}
                    name={[name, "discountTypeId"]}
                    fieldKey={[fieldKey, "taxId"]}
                    label="Discount Type"
                    className="h-16 flex-1 mt-2"
                  >
                    <CustomSelect
                      onChange={(value) => OnchangeDiscountType(value, name)}
                      options={dropDownPayloadLov(discountType)}
                    />
                  </Form.Item>

                  {/* Retail Price */}
                  <Form.Item
                    {...restField}
                    name={[name, "retailPrice"]}
                    fieldKey={[fieldKey, "retailPrice"]}
                    label="Retail Price (Ex. Tax)"
                    rules={[
                      { required: true, message: "Please enter retail price" },
                    ]}
                    className="flex-1"
                  >
                    <Input
                      onChange={(e) => handlePriceChange(name)}
                      disabled
                      placeholder="Rs.0.00"
                      type="number"
                      className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    name={[name, "taxId"]}
                    fieldKey={[fieldKey, "taxId"]}
                    label="Tax Rate"
                    className="flex-1"
                  >
                    <Select
                      placeholder="Tax"
                      className="h-auto mt-2"
                      onChange={(value) => handlePriceChange(name)}
                    >
                      {tax.map((data, key) => {
                        return (
                          <Option key={key} value={data._id}>
                            {data.name} {data.rate}%
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  {/* Retail Price Incl. Tax */}
                  <Form.Item
                    {...restField}
                    name={[name, "retailPriceInclTax"]}
                    fieldKey={[fieldKey, "retailPriceInclTax"]}
                    label="Retail Price (Inc. Tax)"
                    rules={[
                      {
                        required: true,
                        message: "Please enter retail price (Inc. Tax)",
                      },
                    ]}
                    className="flex-1"
                  >
                    <Input
                      onChange={(e) => handlePriceChange(name)}
                      placeholder="Rs.0.00"
                      type="number"
                      disabled
                      className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                    />
                  </Form.Item>
                </div>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
}

export default Pricing;
