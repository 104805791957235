import React, { useState } from "react";
import CustomModal from "../../../component/shared/modal";
import SearchInput from "../../../component/shared/searchInput";
import CustomTable from "../../../component/shared/customTable";
import AddBrands from "../../../component/products/addBrands";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import { Form } from "antd";
import {
  createSlug,
  deleteslug,
  updateSlug,
} from "../../../services/product/brand";

function Unit({ type, heading }) {
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [id, setId] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "",
      dataIndex: "checkbox",
      key: "checkbox",
      render: () => <input type="checkbox" />,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Products",
      dataIndex: "products",
      key: "products",
      align: "center",
      render: (_, row) => {
        return row?.products || "0";
      },
    },
  ];
  const onFinish = async (values) => {
    let response;

    try {
      const payload = {
        ...values,
        isActive: true,
      };
      if (id) {
        response = await updateSlug(type, payload, id);
      } else {
        response = await createSlug(type, payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Status updated successfully");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };

  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteslug(type, row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onClickHandle = (row) => {
    setId(row._id);
    form.setFieldsValue(row);
    setIsModalOpen(true);
  };

  return (
    <main className="mt-6">
      <div className="mt-8 px-6 py-8 bg-white rounded-3xl">
        <div className="flex items-center justify-between mb-8 text-left">
          <h2 className="text-2xl font-medium flex-1">{heading}</h2>
          <a
            onClick={() => openModal()}
            id="addProductTypeBtn"
            className="block capitalize py-4 px-8 rounded-full leading-none text-bold bg-redOrange text-white"
          >
            Add {heading}
          </a>

          <CustomModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            heading={`Add ${heading}`}
          >
            <AddBrands form={form} onFinish={onFinish} />
          </CustomModal>
        </div>
        <SearchInput setSearchText={setSearchText} />
        {/* Records */}
        <div className="flex flex-col justify-between min-h-96">
          <CustomTable
            columns={columns}
            endPoint={`product-slug/${type}`}
            refreshTable={refreshTable}
            search={searchText}
            handleEdit={onClickHandle}
            handleDelete={onClickDelete}
            showActions={true}
          />
        </div>
      </div>
    </main>
  );
}

export default Unit;
