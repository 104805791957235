import Api from "../../../network/client";

export const changeSlugStatus = async (id, slug, data) => {
  return Api.put(`product-slug/${slug}/${id}`, data);
};
export const createSlug = async (slug, data) => {
  return Api.post(`product-slug/${slug}`, data);
};
export const updateSlug = async (slug, data, id) => {
  return Api.put(`product-slug/${slug}/${id}`, data);
};
export const getAllSlugApi = async (slug) => {
  return Api.get(`product-slug/${slug}`);
};
export const deleteslug = async (slug, id) => {
  return Api.delete(`product-slug/${slug}/${id}`);
};
