// Local
// export const API_BASE_URL = "http://localhost:3001/api/v1";

// heroku
// export const API_BASE_URL =
//   "https://poxfy-api-671911087a12.herokuapp.com/api/v1";

// client server alma linux
export const API_BASE_URL = "/api/v1";

export const Days = [
  { dayId: "monday", dayName: "Monday" },
  { dayId: "tuesday", dayName: "Tuesday" },
  { dayId: "wednesday", dayName: "Wednesday" },
  { dayId: "thursday", dayName: "Thursday" },
  { dayId: "friday", dayName: "Friday" },
  { dayId: "saturday", dayName: "Saturday" },
  { dayId: "sunday", dayName: "Sunday" },
];

export const DaysName = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
