import React, { useEffect, useState } from "react";
import {
  changeUserStatus,
  createUser,
  deleteUserApi,
  getAllUserApi,
  updateUser,
} from "../../services/user";
import { Form, Image } from "antd";
import CustomModal from "../../component/shared/modal";
import SearchInput from "../../component/shared/searchInput";
import CustomTable from "../../component/shared/customTable";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";
import AddUser from "../../component/user/addUser";
import { getAllRoleApi } from "../../services/user/role";

function User() {
  const [searchText, setSearchText] = useState("");
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const [role, setRole] = useState([]);
  const [form] = Form.useForm();

  const getAllRole = async () => {
    try {
      const { data: response } = await getAllRoleApi();
      const { data: record } = response;
      console.log(record);
      const tempData = record.data.map((record) => ({
        label: record.name,
        value: record._id,
      }));
      setRole(tempData);
    } catch {
      return ServerError();
    }
  };

  useEffect(() => {
    getAllRole();
  }, []);

  const onClickStatus = async (row) => {
    try {
      const payload = {
        ...row,
        isActive: !row.isActive,
        parentId: null,
      };
      const { data } = await changeUserStatus(row._id, payload);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Status updated successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };
  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteUserApi(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onFinish = async (values) => {
    let response;
    try {
      const payload = {
        ...values,
        isActive: true,
      };
      if (id) {
        response = await updateUser(payload, id);
      } else {
        response = await createUser(payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Status updated successfully");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };

  const onClickEdit = async (row) => {
    setId(row._id);
    setUploadedUrl(row.avatar);
    form.setFieldsValue(row);
    setIsModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "User Type",
      dataIndex: "userType",
      key: "userType",
      render: (_, row) => <p>{row.userType.name}</p>,
    },
  ];

  return (
    <main className="mt-6">
      <div className="mt-8 px-6 py-8 bg-white rounded-3xl">
        <div className="flex items-center justify-between mb-8 text-left">
          <h2 className="text-2xl font-medium flex-1">Users</h2>
          <a
            onClick={() => openModal()}
            id="addProductTypeBtn"
            className="block capitalize py-4 px-8 rounded-full leading-none text-bold bg-redOrange text-white"
          >
            Add user
          </a>

          <CustomModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            heading={"Add User"}
          >
            <AddUser
              form={form}
              onFinish={onFinish}
              role={role}
              uploadedUrl={uploadedUrl}
              setUploadedUrl={setUploadedUrl}
            />
          </CustomModal>
        </div>
        <SearchInput setSearchText={setSearchText} />
        {/* Records */}
        <div className="flex flex-col justify-between min-h-96">
          <CustomTable
            refreshTable={refreshTable}
            columns={columns}
            endPoint={"user"}
            search={searchText}
            handleStatus={onClickStatus}
            handleDelete={onClickDelete}
            handleEdit={onClickEdit}
            showActions={true}
          />
        </div>
      </div>
    </main>
  );
}

export default User;
