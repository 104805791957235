import { Button, Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { openNotification } from "../../shared/notification";
import { getAllAttributeApi } from "../../../services/product";

function AddVariants({
  mainForm,
  form,
  fields,
  setFields,
  onFinish,
  isModalOpen,
  product,
  setAttributeState,
}) {
  const [defaultAttribute, setDefaultAttribute] = useState([]);
  const [defaultAttributeValue, setDefaultAttributeValue] = useState([]);

  const getAllAttribute = async () => {
    try {
      const { data: response } = await getAllAttributeApi();
      const { data: record } = response;
      const attribute = record.map((item) => ({
        value: item.type.toLowerCase(),
        label: item.type,
      }));
      setDefaultAttribute(attribute);
      const attributeValuesOptions = {};
      record.forEach((item) => {
        attributeValuesOptions[item.type.toLowerCase()] = item.values.map(
          (value) => ({
            value: value.toLowerCase(),
            label: value,
          }),
        );
      });
      setDefaultAttributeValue(attributeValuesOptions);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllAttribute();
  }, []);

  function updateResultType(result, defaultAttributeValue) {
    // Loop through each item in result
    result.forEach((item) => {
      // Iterate over all types in defaultAttributeValue
      for (const [attributeType, valuesList] of Object.entries(
        defaultAttributeValue,
      )) {
        // Extract the values for the current attribute type
        const attributeValues = valuesList.map((entry) =>
          entry.value.toLowerCase(),
        );
        // Check if any of the item's values match this attribute's values
        if (
          item.values.some((value) =>
            attributeValues.includes(value.toLowerCase()),
          )
        ) {
          item.type = [attributeType];
          break; // Stop at the first match
        }
      }
    });
    return result;
  }
  useEffect(() => {
    const { variants } = mainForm.getFieldsValue();
    // Initialize an object to store attributes
    const attributes = {};

    // Loop through each variant and dynamically populate attributes by position
    variants?.forEach((variant) => {
      variant?.combination?.forEach((attr, index) => {
        const key = `attribute${index + 1}`;
        if (!attributes[key]) {
          attributes[key] = new Set(); // Use Set to avoid duplicates
        }
        attributes[key].add(attr);
      });
    });

    // Convert Sets to Arrays for easier usage in React
    const result = Object.fromEntries(
      Object.entries(attributes).map(([key, value]) => [
        key,
        Array.from(value),
      ]),
    );

    let result1 = [];
    let key = 1;

    for (const attribute in result) {
      result1.push({
        key: key++,
        type: [attribute],
        values: result[attribute],
      });
    }
    const updatedPayload = updateResultType(result1, defaultAttributeValue);
    setFields(updatedPayload);
  }, [mainForm, isModalOpen]);

  useEffect(() => {
    const formData = mainForm.getFieldsValue();
    const { variants } = formData;
    if (variants && variants.length > 0) {
      const filledFields = product?.attributes?.map((record, index) => ({
        key: index,
        type: record.type,
        values: record.values,
      }));
      if (filledFields) {
        // setFields(filledFields);
      }
    }
  }, [mainForm, isModalOpen]);

  // Add new row
  const addRow = () => {
    if (fields?.length < 3) {
      setFields([...fields, { key: fields.length + 1, type: [], values: [] }]);
    } else {
      openNotification("error", "You can only select upto 3 variations");
    }
  };

  // Delete row
  const removeRow = (key) => {
    setFields(fields.filter((field) => field.key !== key));
  };

  // Handle changes for each row (Select and Input)
  const handleFieldChange = (key, fieldType, value) => {
    const updatedFields = fields.map((field) => {
      if (field.key === key) {
        if (fieldType === "type") {
          // Clear the 'values' field if the attribute type changes
          return { ...field, type: value[0], values: [] };
        }
        return { ...field, [fieldType]: value };
      }
      return field;
    });
    updatedFields.forEach((item) => {
      if (Array.isArray(item.type)) {
        item.type = item.type.length > 1 ? item.type.join(", ") : item.type[0];
      }
    });
    setAttributeState(updatedFields);
    setFields(updatedFields);
  };

  // Get dynamic options based on the selected attribute type
  const getDynamicOptions = (type) => {
    return defaultAttributeValue[type] || [];
  };

  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="on"
      >
        {fields?.map((field) => (
          <div key={field.key} className="flex gap-2 mt-4 w-100">
            <Form.Item
              style={{ width: "40%" }}
              rules={[
                {
                  required: true,
                  message: "Please select or add an attribute",
                },
              ]}
            >
              <Select
                value={field.type}
                mode="tags"
                maxCount={1}
                onChange={(value) =>
                  handleFieldChange(field.key, "type", value)
                }
                options={defaultAttribute}
                placeholder="Select or add an attribute"
                style={{ width: "100%" }}
              />
            </Form.Item>

            {/* Dynamic Select (for attribute values) */}
            <Form.Item
              style={{ width: "60%" }}
              rules={[
                { required: true, message: "Please select or add values" },
              ]}
            >
              <Select
                mode="tags" // Enable `tags` mode for the values select
                style={{ width: "100%" }}
                value={field.values}
                onChange={(value) =>
                  handleFieldChange(field.key, "values", value)
                }
                tokenSeparators={[","]}
                options={getDynamicOptions(field.type)} // Dynamically load options based on selected type
                placeholder="Select or add values"
              />
            </Form.Item>

            {fields.length > 1 && (
              <Button
                className="cursor-pointer leading-none block px-2 bg-redOrange rounded-full  
                                text-white font-medium mt-2"
                onClick={() => removeRow(field.key)}
              >
                <DeleteOutlined />
              </Button>
            )}
          </div>
        ))}
        {fields?.length < 3 && (
          <div className="flex justify-start">
            <a type="dashed" onClick={addRow} className="add-row-link">
              Add Row
            </a>
          </div>
        )}
        <div className="flex justify-end mt-4">
          <Form.Item>
            <input
              type="submit"
              defaultValue="Save"
              className="leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium border border-redOrange"
            />
          </Form.Item>
        </div>
      </Form>
    </>
  );
}

export default AddVariants;
