import Api from "../../network/client";

export const changeUserStatus = async (id, data) => {
  return Api.put(`user/${id}`, data);
};
export const createUser = async (data) => {
  return Api.post(`user`, data);
};
export const updateUser = async (data, id) => {
  return Api.put(`user/${id}`, data);
};
export const getAllUserApi = async () => {
  return Api.get(`user`);
};
export const getAllUserPermissionsApi = async () => {
  return Api.get(`user`);
};
export const deleteUserApi = async (id) => {
  return Api.delete(`user/${id}`);
};
