export const getAPIUrl = (endPoint, queryParams) => {
  if (!endPoint) return "";
  if (!queryParams) return endPoint;

  let queryString = "";
  for (const key in queryParams) {
    const value = queryParams[key];
    if (value) {
      queryString += `${queryString ? "&" : ""}${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    }
  }

  const apiUrl = `${endPoint}?${queryString}`;
  return apiUrl;
};

export const dropDownPayload = (payload, valueKey, extraKey) => {
  if (payload?.length) {
    return payload?.map((item) => ({
      label: item?.name || item?.fullName,
      // this key is by default id but if author want any other value to be the value of `value key` now author can
      value: item?._id,
      ...item,
      // one can add extra key as per payload OR business requirements
      ...(extraKey && { extraKey: item[extraKey] }),
    }));
  } else {
    return [];
  }
};

export const CalculateTotalPrice = (items) => {
  let total = 0;
  items.forEach((item) => {
    item.variants.forEach((variant) => {
      let discount = variant.discount || 0;
      let quantity = variant.quantity || 1;
      let retailPrice = variant.retailPriceInclTax || 0;

      // Apply discount based on discountType
      if (variant.discountType === "percentage") {
        discount = (Number(retailPrice) * Number(discount)) / 100;
      } else if (variant.discountType === "fix_rate") {
        // discount remains as the fixed rate
        discount = (Number(retailPrice) - Number(discount)) / 100;
      }

      // Calculate total for this variant after discount
      let variantTotal = (retailPrice - discount) * quantity;
      total += variantTotal;
    });
  });
  return total;
};

export const dropDownPayloadLov = (payload, valueKey, extraKey) => {
  if (payload?.length) {
    return payload?.map((item) => ({
      label: item.name ? item.name : item.value,
      // this key is by default id but if author want any other value to be the value of `value key` now author can
      value: item._id,
      // one can add extra key as per payload OR business requirements
      ...(extraKey && { extraKey: item[extraKey] }),
    }));
  } else {
    return [];
  }
};

export const addDayIdToSchedule = (schedule, ids) => {
  // Create a mapping of key to _id from the ids array
  const idMap = {};
  ids.forEach((item) => {
    idMap[item.key] = item.dayId; // Map the key to its corresponding _id
  });

  // Update the schedule array to include dayId
  return schedule.map((item) => {
    item.dayId = idMap[item.key] || null; // Add dayId from the map, or set to null if not found
    return item; // Return the updated item
  });
};

export function formatNames(arr) {
  return arr?.map((x) => x.toUpperCase())?.join(" / ");
}

export const dropDownPayloadForAttribute = (payload, valueKey, extraKey) => {
  if (payload?.length) {
    return payload?.map((item) => ({
      label: item.type ? item.type : item.type,
      // this key is by default id but if author want any other value to be the value of `value key` now author can
      value: item.type,
      // one can add extra key as per payload OR business requirements
      ...(extraKey && { extraKey: item[extraKey] }),
    }));
  } else {
    return [];
  }
};

export function calculateDiscountedPrice(
  retailPriceInclTax,
  discount,
  quantity,
  discountType,
) {
  let discountedPrice;

  if (discountType === "fix_rate") {
    // Fixed rate discount calculation
    discountedPrice =
      (Number(retailPriceInclTax) - Number(discount)) * Number(quantity);
  } else if (discountType === "percentage") {
    // Percentage discount calculation
    discountedPrice =
      (Number(retailPriceInclTax) -
        Number(retailPriceInclTax) * (Number(discount) / 100)) *
      Number(quantity);
  } else {
    throw new Error("Invalid discount type");
  }

  return Number(discountedPrice);
}

export const CalculateTotalDiscount = (items) => {
  let totalDiscount = 0;

  items.forEach((item) => {
    item.variants.forEach((variant) => {
      let discount = variant.discount || 0;
      let retailPrice = variant.retailPriceInclTax || 0;
      let quantity = variant.quantity || 1;
      let discountAmount = 0;

      // Calculate discount amount based on discountType
      if (variant.discountType === "percentage") {
        discountAmount = (retailPrice * discount) / 100;
      } else if (variant.discountType === "fix_rate") {
        discountAmount = discount;
      }

      // Total discount for this variant (after considering quantity)
      totalDiscount += discountAmount * quantity;
    });
  });

  return totalDiscount;
};
