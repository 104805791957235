import React, { useState } from "react";
import CustomModal from "../../component/shared/modal";
import SearchInput from "../../component/shared/searchInput";
import CustomTable from "../../component/shared/customTable";
import {
  changeRegisterStatus,
  createRegister,
  deleteRegisterApi,
  updateRegister,
} from "../../services/outlet/index";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";
import { Form } from "antd";
import AddCashRegister from "../../component/outlet/addCashRegister";

function Register() {
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const [form] = Form.useForm();

  const onClickStatus = async (row) => {
    try {
      const payload = {
        ...row,
        isActive: !row.isActive,
        parentId: null,
      };
      const { data } = await changeRegisterStatus(row._id, payload);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Status updated successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };
  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteRegisterApi(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onFinish = async (values) => {
    let response;
    try {
      const payload = {
        ...values,
        isActive: true,
      };
      if (id) {
        response = await updateRegister(payload, id);
      } else {
        response = await createRegister(payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Status updated successfully");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };
  const onClickEdit = async (row) => {
    setId(row._id);
    form.setFieldsValue(row);
    setIsModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Receipt No",
      dataIndex: "receiptNo",
      key: "receiptNo",
    },
    {
      title: "Receipt Prefix",
      dataIndex: "receiptPrefix",
      key: "receiptPrefix",
    },
    {
      title: "Receipt Suffix",
      dataIndex: "receiptSuffix",
      key: "receiptSuffix",
    },
    {
      title: "Outlet",
      dataIndex: "outlet",
      key: "outlet",
      render: (_, row) => {
        return row?.outlet?.name;
      },
    },
  ];

  const onClickAdd = async () => {
    openModal();
    setId(null);
    form.resetFields();
  };

  return (
    <main className="mt-6">
      <div className="mt-8 px-6 py-8 bg-white rounded-3xl">
        <div className="flex items-center justify-between mb-8 text-left">
          <h2 className="text-2xl font-medium flex-1">Register Management</h2>
          <a
            onClick={onClickAdd}
            id="addOutletBtn"
            className="block capitalize py-4 px-8 rounded-full leading-none text-bold bg-redOrange text-white"
          >
            Add Register
          </a>

          <CustomModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            heading={"Register"}
            className="max-w-[70%]"
          >
            <AddCashRegister form={form} onFinish={onFinish} />
          </CustomModal>
        </div>
        <SearchInput setSearchText={setSearchText} />
        {/* Records */}
        <div className="flex flex-col justify-between min-h-96">
          <CustomTable
            refreshTable={refreshTable}
            columns={columns}
            endPoint={"cash-register"}
            search={searchText}
            handleStatus={onClickStatus}
            handleDelete={onClickDelete}
            handleEdit={onClickEdit}
            showActions={true}
          />
        </div>
      </div>
    </main>
  );
}

export default Register;
