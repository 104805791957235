import React, { useEffect, useState } from "react";
import { getSelectedOutlet } from "../../redux/feature/outletSlice";
import { useSelector } from "react-redux";
import { openNotification, ServerError } from "../shared/notification";
import { createSales } from "../../services/sales";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import {
  CalculateTotalDiscount,
  CalculateTotalPrice,
} from "../../utils/helper";

function Payment({ selectedProductState, isModalOpen, customerID, notes }) {
  const navigate = useNavigate();
  const { selectedOutlet } = useSelector(getSelectedOutlet) || {};
  const [totalPriceIncTax, setRotalPriceIncTax] = useState("");
  const [retailPriceState, setRetailPriceState] = useState("");

  useEffect(() => {
    const CalculateTotalPrice = (items) => {
      let total = 0;
      items.forEach((item) => {
        item.variants.forEach((variant) => {
          let discount = variant.discount || 0;
          let quantity = variant.quantity || 1;
          let retailPrice = variant.retailPriceInclTax || 0;

          // Apply discount based on discountType
          if (variant.discountType === "percentage") {
            discount = (Number(retailPrice) * Number(discount)) / 100;
          } else if (variant.discountType === "fix_rate") {
            // discount remains as the fixed rate
            discount = (Number(retailPrice) - Number(discount)) / 100;
          }

          // Calculate total for this variant after discount
          let variantTotal = (retailPrice - discount) * quantity;
          total += variantTotal;
        });
      });
      return total;
    };
    const retailPrice = selectedProductState.reduce((total, product) => {
      return (
        total +
        product.variants.reduce(
          (sum, variant) => sum + Number(variant.retailPrice),
          0,
        )
      );
    }, 0);
    setRotalPriceIncTax(CalculateTotalPrice(selectedProductState));
    setRetailPriceState(retailPrice);
  }, [selectedProductState, isModalOpen]);
  const onClickSubmit = async (paymentType) => {
    const allVariants = selectedProductState.flatMap((product) =>
      product.variants.map((variant) => ({
        ...variant,
        productName: product.name,
        productId: product._id,
        quantity: variant.quantity ? Number(variant.quantity) : 1,
        total: 100,
        price: variant.retailPriceInclTax,
      })),
    );
    if (customerID.length) {
      const payload = {
        outletId: selectedOutlet._id,
        orderDate: new Date(),
        customerId: customerID,
        paymentStatus: "PARKED",
        paymentType,
        notes: notes,
        saleDetails: allVariants,
        received: Number(totalPriceIncTax),
        surcharge: 0,
      };

      try {
        const response = await createSales(payload);
        if (response.data.meta.success) {
          navigate("../pos/sale-history");
          openNotification("success", "Status updated successfully");
        } else {
          openNotification("error", response.data.error);
        }
      } catch (err) {
        console.log(err);
        ServerError();
      }
    } else {
      openNotification("error", "Please Add Customer");
    }
  };
  return (
    <>
      <div className="grid grid-cols-12">
        <div className="col-span-5 m-5">
          <h3 className="text-lg text-ufoGreen font-medium border-y border-lightGrey py-3">
            Sale Summary
          </h3>
          <table className="mt-2 w-full">
            <tbody>
              <tr>
                <td className="py-2  border-lightGrey text-lg font-medium">
                  After Discount:
                </td>
                <td className="py-2 border-lightGrey text-lg font-medium w-24">
                  {CalculateTotalPrice(selectedProductState)}
                </td>
              </tr>
              <tr>
                <td className="py-2 border-y text-xl font-bold">To Pay:</td>
                <td className="py-2 text-xl border-y font-bold w-24">
                  {totalPriceIncTax}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-span-7 border border-lightGrey rounded-3xl rounded-tl-none px-5 pt-5 pb-10">
          <div>
            <label className="block text-left">
              <span className="block text-sm font-medium mb-4">
                Amount Tendered
              </span>
              <input
                type="text"
                disabled
                value={totalPriceIncTax}
                // defaultValue={Number(totalPriceIncTax)}
                className="block w-full text-5xl font-medium leading-none py-3 text-center border border-lightGrey rounded-lg"
              />
            </label>
            {/* <div className="flex flex-wrap items-center justify-start mt-4 gap-3.5">
              <div className="text-[0.67rem] min-w-16 text-center text-lightGrey font-medium py-2 px-3 bg-veryLightGrey border border-lightBlue rounded-full block leading-none">
                Rs100.00
              </div>
              <div className="text-[0.67rem] min-w-16 text-center text-lightGrey font-medium py-2 px-3 bg-veryLightGrey border border-lightBlue rounded-full block leading-none">
                Rs100.00
              </div>
              <div className="text-[0.67rem] min-w-16 text-center text-lightGrey font-medium py-2 px-3 bg-veryLightGrey border border-lightBlue rounded-full block leading-none">
                Rs100.00
              </div>
              <div className="text-[0.67rem] min-w-16 text-center text-lightGrey font-medium py-2 px-3 bg-veryLightGrey border border-lightBlue rounded-full block leading-none">
                Rs100.00
              </div>
            </div> */}
            <div className="flex items-center justify-center gap-3 mt-6">
              <Button
                type="primary"
                onClick={() => onClickSubmit("CARD")}
                htmlType="button"
                className="h-auto cursor-pointer leading-none flex py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
              >
                Credit / Debit Card
              </Button>
              <Button
                type="primary"
                onClick={() => onClickSubmit("CASH")}
                htmlType="button"
                className="h-auto cursor-pointer leading-none flex py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
              >
                Cash
              </Button>
              {/* <input
                type="submit"
                defaultValue="Card"
                className="cursor-pointer leading-none block py-4 px-10 bg-veryLightGrey rounded-full text-lightGrey font-medium border border-lightGrey"
              />
              <input
                
                type="submit"
                defaultValue="Cash"
                className="cursor-pointer leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium border border-redOrange"
              /> */}
            </div>
            {/* <label className="block mt-10">
              <input
                type="text"
                defaultValue="Walk In"
                className="block w-full text-3xl leading-none px-5 py-1.5 border border-lightGrey rounded-lg"
              />
            </label> */}
            {/* <div className="flex flex-wrap items-center justify-start mt-4 gap-3.5">
              <div className="text-[0.67rem] min-w-16 text-center text-lightGrey font-medium py-2 px-3 bg-veryLightGrey border border-lightBlue rounded-full block leading-none">
                Store Credit
              </div>
              <div className="text-[0.67rem] min-w-16 text-center text-lightGrey font-medium py-2 px-3 bg-veryLightGrey border border-lightBlue rounded-full block leading-none">
                On Account
              </div>
              <div className="text-[0.67rem] min-w-16 text-center text-lightGrey font-medium py-2 px-3 bg-veryLightGrey border border-lightBlue rounded-full block leading-none">
                layby
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Payment;
