import PublicLayout from "../../component/layout/publicLayout";
import Login from "../../pages/auth/login";
import ForgetPassword from "../../pages/auth/login/forgetPassword";
import ResetPassword from "../../pages/auth/login/resetPassword";

export const PublicRoutes = [
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      {
        path: "/",
        element: <Login />,
      },
      {
        path: "/forget-password",
        element: <ForgetPassword />,
      },
      {
        path: "/reset-password/:id",
        element: <ResetPassword />,
      },
    ],
  },
];
