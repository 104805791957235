import React, { useEffect, useRef } from "react";

function FilterModal({ isModalOpen, onClose, children }) {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    if (isModalOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isModalOpen, onClose]);

  return (
    <div>
      {isModalOpen && (
        <div className="overlay fixed top-0 bottom-0 left-0 w-full h-full bg-black opacity-35 z-10" />
      )}
      <div
        id="productFilterPopup"
        ref={modalRef}
        className={`${!isModalOpen && "hidden"} absolute right-0 top-full bg-white rounded-2xl py-7 px-7 z-20 mt-4 min-w-[64rem] flex gap-5 items-center justify-start`}
      >
        {children}
      </div>
    </div>
  );
}

export default FilterModal;
