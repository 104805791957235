import React from "react";

import { Outlet } from "react-router-dom";

const PublicLayout = () => {
  return (
    <>
      <body class="bg-lightBlue">
        <section class="h-dvh relative overflow-hidden">
          <div class="absolute -right-1/4 -top-2/4 w-3/6 aspect-square rounded-full bg-[rgba(0,227,125,0.2)] blur-3xl"></div>
          <div class="absolute -left-1/4 -bottom-2/4 w-3/6 aspect-square rounded-full bg-[rgba(254,94,94,0.2)] blur-3xl"></div>
          <Outlet />
        </section>
      </body>
    </>
  );
};

export default PublicLayout;
