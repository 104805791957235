import React, { useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import { getData, submitContent } from "./service";

function Business() {
  const [form] = Form.useForm();
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [content, setContent] = useState({});

  // Handle pressing "Enter" to create a new tag
  const handleKeyDown = (e) => {
    if (!tags) {
      setTags([]);
    }
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault();
      setTags([...tags, inputValue.trim()]);
      setInputValue("");
    }
  };

  // Handle removing a tag
  const handleRemoveTag = (indexToRemove) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
  };

  const onFinish = async (values) => {
    const payload = {
      value: {
        ...values,
        _id: content.value.id,
        tags: tags,
      },
    };
    try {
      const response = await submitContent(content._id, payload);
      if (response?.status == 200) {
        message.success("Content Published");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getContent = async () => {
    const response = await getData();
    const {
      data: { data },
    } = response;
    setContent(data.data[0]);
    form.setFieldsValue(data.data[0].value);
    setTags(data.data[0].value.tags);
  };

  useEffect(() => {
    getContent();
  }, []);

  return (
    <>
      <div className="bg-white p-6 rounded-2xl">
        <h3 className="text-ufoGreen text-xl font-medium border-b pb-2.5 flex justify-start">
          Business Start
        </h3>
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          className="mt-5"
        >
          <Form.Item
            label={
              <div className={"text-black text-md ml-2 font-medium"}>
                Business Start Section Tagline{" "}
              </div>
            }
            name="tagLine"
            rules={[
              { required: true, message: "Please input the About Tagline!" },
            ]}
          >
            <Input
              placeholder="Hero Tagline"
              className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full mt-2 outline-0"
            />
          </Form.Item>

          <Form.Item
            label={
              <div className={"text-black text-md ml-2 font-medium"}>
                Business Start Section Title{" "}
              </div>
            }
            name="title"
            rules={[
              { required: true, message: "Please input the About Title!" },
            ]}
          >
            <Input
              placeholder="Hero Title"
              className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full mt-2 outline-0"
            />
          </Form.Item>

          <Form.Item
            label={
              <div className={"text-black text-md ml-2 font-medium"}>
                Business Start Section Description{" "}
              </div>
            }
            name="description"
            rules={[
              { required: true, message: "Please input the Hero Description!" },
            ]}
          >
            <Input.TextArea placeholder="Hero Description" rows={10} />
          </Form.Item>
          <Form.Item
            label={
              <div className={"text-black text-md ml-2 font-medium"}>
                Business Start Support List{" "}
              </div>
            }
            name="tags"
          >
            <div className="w-full">
              <div className="border border-lightGrey  p-2 mt-2 rounded-full flex flex-wrap gap-2">
                {/* Render tags */}
                {tags?.map((tag, index) => (
                  <div
                    key={index}
                    className="inline-flex items-center bg-lightGreen text-white py-1 px-3 rounded-full chip-in-tag"
                  >
                    {tag}
                    <span
                      className="ml-2 text-white cursor-pointer"
                      onClick={() => handleRemoveTag(index)}
                    >
                      ✕
                    </span>
                  </div>
                ))}

                <input
                  className="flex-1 leading-none py-2 px-2 outline-0"
                  type="text"
                  placeholder="Type and press enter..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </Form.Item>

          <Form.Item>
            <div className="flex justify-end gap-3">
              <input
                type="submit"
                defaultValue="Cancel"
                className="cursor-pointer leading-none block py-4 px-10 bg-veryLightGrey rounded-full text-lightGrey font-medium border border-lightGrey"
              />
              <input
                type="submit"
                defaultValue="Save"
                className="cursor-pointer leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium border border-redOrange"
              />
            </div>
          </Form.Item>
        </Form>
      </div>
    </>
  );
}

export default Business;
