import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectedOutlet: {},
};

const outletSlice = createSlice({
  name: "outlet",
  initialState: initialState,
  reducers: {
    setSelectedOutlet: (state, payload) => {
      state.selectedOutlet = payload.payload;
    },
  },
});

export const { setSelectedOutlet } = outletSlice.actions;

export const getSelectedOutlet = (state) => state.outlet;

export default outletSlice.reducer;
