import Api from "../../../network/client";

export const submitLogin = async (data) => {
  return Api.post(`auth/login`, data);
};

export const forgetEmail = async (email) => {
  return Api.get(`auth/forgot-password/${email}`);
};

export const confirmPassword = async (data) => {
  return Api.post(`auth/reset-password`, data);
};
