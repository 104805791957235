import React, { useState } from "react";
import FilterModal from "../../../component/products/filterModal";
import Filter from "../../../component/products/filter";
import CustomTable from "../../../component/shared/customTable";
import { Link, useNavigate } from "react-router-dom";
import SalesFilter from "../../../component/pos/salesHistory/salesFilter";

function POS() {
  const navigate = useNavigate();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const onClose = () => {
    setIsFilterOpen(false);
  };

  const columns = [
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      render: (row) => row.fullName,
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Remaining Balance",
      dataIndex: "remainingBalance",
      key: "remainingBalance",
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    {
      title: "Sale Total",
      dataIndex: "subTotal",
      key: "subTotal",
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
    },
  ];

  return (
    <main className="mt-6">
      <div className="mt-8 px-6 py-8 bg-white rounded-3xl">
        <div className="flex items-center justify-between mb-8">
          <h2 className="text-2xl font-medium">Sales History</h2>
        </div>
        <form action="" className="relative mb-6">
          <input
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Find product by name, barcode"
            className="block leading-none py-3 pr-10 pl-7 border w-full rounded-full"
          />
          <button
            type="button"
            onClick={() => setIsFilterOpen(true)}
            className="productFilterBtn absolute right-0 top-0 bg-veryLightGrey h-full rounded-r-full flex items-center justify-center min-w-32 border border-lightBlue text-lightGrey font-medium [&.active]:bg-redOrange [&.active]:text-white [&.active]:border-redOrange"
          >
            More Filters
          </button>
          <div className="overlay hidden fixed top-0 bottom-0 left-0 w-full h-100 bg-black z-10 opacity-35" />
          <FilterModal isModalOpen={isFilterOpen} onClose={onClose}>
            <SalesFilter />
          </FilterModal>
        </form>
        {/* Records */}
        <div className="flex flex-col justify-between min-h-96">
          <CustomTable
            columns={columns}
            endPoint={"sales"}
            search={searchText}
            // handleEdit={onClickEdit}
            // handleDelete={onClickStatus}
            // handleView={onClickStatus}
            showActions={false}
          />
        </div>
      </div>
    </main>
  );
}

export default POS;
