import { Button, Form, Input } from "antd";
import React from "react";

function ForgetPassword({ form, onFinish }) {
  return (
    <>
      <Form
        form={form}
        name="forgetPassword"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          name="password"
          label={<span className="text-lightGrey font-medium">Password</span>}
          rules={[{ required: true, message: "Password is required" }]}
          hasFeedback
        >
          <Input
            type="password"
            className="w-full border focus:border-ufoGreen rounded-full py-3 px-6 flex"
            placeholder="Enter old password"
          />
        </Form.Item>
        <Form.Item
          hasFeedback
          dependencies={["password"]}
          name="confirmPassword"
          label={
            <span className="text-lightGrey font-medium">New Password</span>
          }
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match!"),
                );
              },
            }),
          ]}
        >
          <Input
            type="password"
            className="w-full border focus:border-ufoGreen rounded-full py-3 px-6 flex"
            placeholder="Enter new Password"
          />
        </Form.Item>

        <div className="flex items-center justify-end gap-3 mt-10">
          <div className="flex items-center justify-end gap-3">
            <Button
              type="primary"
              htmlType="submit"
              className="h-auto cursor-pointer leading-none block py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default ForgetPassword;
