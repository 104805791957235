import React from "react";
import CustomModal from "../shared/modal";
import AddProductToProcrss from "./addProductToProcrss";

function ProductListing({
  products,
  handleAddSelectedProduct,
  onClickCloseCombination,
  combinationModal,
  form,
  onFinishListingModal,
  selecectedAttribute,
  tempAttibute,
}) {
  return (
    <div className="grid grid-cols-5 gap-3">
      {products.map((data, index) => {
        return (
          <a
            key={index + 1}
            onClick={() => handleAddSelectedProduct(data)}
            className="productSaleItem bg-white rounded-3xl border border-veryLightGrey overflow-hidden"
          >
            <img
              src={data?.images?.length ? data.images[0] : ""}
              alt=""
              width=""
              height=""
              className="w-full aspect-[1/0.92] object-contain bg-brightRedOrange rounded-2xl p-4"
            />
            <div className="mt-3 text-center font-medium text-md mb-3">
              {data.name}
            </div>
            {/* Product Popup */}
            <div className="overlay hidden fixed top-0 bottom-0 left-0 w-full h-100 bg-black z-10 opacity-35" />
          </a>
        );
      })}

      <CustomModal
        isModalOpen={combinationModal}
        closeModal={onClickCloseCombination}
        heading={form.getFieldValue("name")}
      >
        <AddProductToProcrss
          form={form}
          onFinishListingModal={onFinishListingModal}
          selecectedAttribute={selecectedAttribute}
          tempAttibute={tempAttibute}
        />
      </CustomModal>
    </div>
  );
}

export default ProductListing;
