import React, { useState } from "react";
import { Form, Input, Row, Col, Button } from "antd";

function AddLoyality({ form, onFinish }) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={24}>
          {/* Left Side Form Fields */}
          <Col span={12}>
            <Form.Item
              name="loyality_amount_on"
              label={
                <span className="text-lightGrey font-medium">
                  Loyality Amount
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                type="number"
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter Amount"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="loyality_points_on"
              label={
                <span className="text-lightGrey font-medium">
                  Loyality Points
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                type="number"
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter Points"
              />
            </Form.Item>
          </Col>
          <Col span={20}></Col>
          <Col span={4}>
            <div className="flex items-center justify-center gap-3 mt-7">
              <Button
                type="primary"
                htmlType="submit"
                className="h-auto cursor-pointer leading-none block py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default AddLoyality;
