import React, { useEffect, useState } from "react";
import { dropDownPayload } from "../../utils/helper";
import { Button, Form, Select } from "antd";
import { ServerError } from "../shared/notification";
import { getAllCustomerApi } from "../../services/customer";
function AddNewCustomerModal({
  customerForm,
  onClickAdd,
  onFinishCustomerModal,
}) {
  const [customer, setCustomer] = useState([]);

  const gatCustomer = async () => {
    try {
      const {
        data: { data },
      } = await getAllCustomerApi();
      setCustomer(data.data);
    } catch {
      ServerError();
    }
  };

  useEffect(() => {
    gatCustomer();
  }, [customerForm]);

  return (
    <>
      <Form onFinish={onFinishCustomerModal} form={customerForm}>
        <Form.Item
          name="customerId"
          className="flex-1"
          rules={[{ required: true, message: "Please enter state" }]}
        >
          <Select
            className="w-[100%] h-auto"
            options={dropDownPayload(customer)}
          />
        </Form.Item>
        <div className="flex items-center justify-center gap-3 mt-7">
          <Button
            type="submit"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none flex py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
          >
            Confirm
          </Button>
        </div>
        <span className="block text-center text-lightGrey font-light my-2">
          Or
        </span>
        <div className="flex items-center justify-center">
          <Button
            type="primary"
            onClick={onClickAdd}
            htmlType="button"
            className="h-auto cursor-pointer leading-none flex py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
          >
            Add Customer
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AddNewCustomerModal;
