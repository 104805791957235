/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu } from "./menu";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux/feature/authSlice";

function SideBar() {
  const [openIndex, setOpenIndex] = useState(null); // Tracks which dropdown is open
  const [menuWithPermission, setMenuWithPermission] = useState([]);
  const { user } = useSelector(getUserDetails) || {};

  useEffect(() => {
    const filteredSidebar = Menu.filter((item) =>
      item.permission.includes(user.userType),
    );
    setMenuWithPermission(filteredSidebar);
  }, []);

  const toggleDropdown = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close the dropdown if it's already open
    } else {
      setOpenIndex(index); // Open the clicked dropdown
    }
  };
  return (
    <aside className="bg-white flex flex-col sticky top-0 left-0 rounded-[1.8rem] flex-none w-52 h-full">
      <a href="index.html" className="block py-8 px-4">
        <img
          src="/img/logo.svg"
          alt="Logo"
          width=""
          height=""
          className="w-24 mx-auto"
        />
      </a>
      <ul className="scrollbar overflow-y-auto">
        {menuWithPermission.map((menus, index) => (
          <li
            key={index}
            className={`group mb-4 last:mb-0 ${menus?.children?.length ? "menu-item-has-children" : ""} ${
              openIndex === index ? "active" : ""
            }`}
          >
            <Link to={menus.to}>
              <div
                onClick={() => toggleDropdown(index)}
                className="relative py-1 px-5 flex items-center justify-start cursor-pointer transition ease-in-out group-hover:bg-redOrange group-[.menu-item-has-children.active]:bg-redOrange"
              >
                {menus.icon}
                <span className="text-sm text-lightGrey transition ease-in-out group-hover:text-white group-[.menu-item-has-children.active]:text-white ml-2">
                  {menus.label}
                </span>

                {menus?.children?.length && (
                  <span class="absolute top-5 right-4 transition ease-in-out group-[.menu-item-has-children.active]:rotate-180">
                    <svg
                      width="12"
                      height="7"
                      viewBox="0 0 12 7"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        class="group-hover:stroke-white group-[.menu-item-has-children.active]:stroke-white"
                        d="M1 1L6 6L11 1"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                )}
              </div>
            </Link>
            {menus.children && openIndex === index && (
              <ul
                className={`bg-veryLightGrey py-2 transition-all ease-in-out duration-300 ${
                  openIndex === index ? "block active" : "hidden"
                }`}
              >
                {menus.children.map((child, childIndex) => (
                  <li key={childIndex}>
                    <Link
                      to={child.to}
                      className="text-left block text-sm text-black py-2 pr-4 pl-10"
                    >
                      {child.label}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </aside>
  );
}

export default SideBar;
