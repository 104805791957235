import { RouterProvider } from "react-router-dom";
import "./App.css";
import appRoutes from "./routes";
import AppLoader from "./component/shared/loader";

function App() {
  return (
    <div className="App">
      <AppLoader />
      <RouterProvider router={appRoutes} />
    </div>
  );
}

export default App;
