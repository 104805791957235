import { Switch } from "antd";

const CustomSwitch = ({ isChecked, onChange, isDisabled = false }) => {
  return (
    <>
      <Switch
        className="bg-redOrange"
        checked={isChecked}
        disabled={isDisabled}
        onChange={onChange}
      />
    </>
  );
};

export default CustomSwitch;
