import React from "react";

const InfoCardDashboard = ({
  todayLabel,
  todayValue,
  yesterdayLabel,
  yesterdayValue,
  imgSrc,
  arrowSvg,
  svgIcon,
  svgIconClassName,
  svgIconBottom,
}) => {
  return (
    <div className="col-span-3 bg-white rounded-3xl relative overflow-hidden">
      {/* Background Image */}
      <img
        src={imgSrc}
        alt="background"
        className="absolute top-0 left-0 w-full h-full object-cover z-0"
      />

      {/* Main Content */}
      <div className="relative flex flex-wrap items-center justify-between">
        <div className="p-5">
          {/* Today's Data */}
          <div className="mb-4 last:mb-0">
            <div className="uppercase text-[0.7rem]">{todayLabel}</div>
            <div className="font-bold text-[1.6rem] leading-7">
              {todayValue}
            </div>
          </div>

          {/* Yesterday's Data */}
          <div className="mb-4 last:mb-0">
            <div className="uppercase text-[0.7rem]">{yesterdayLabel}</div>
            <div className="font-bold text-[1.6rem] leading-7">
              {yesterdayValue}
            </div>
          </div>
        </div>

        {/* Icon with SVG */}
        <div className="relative w-28 p-5">
          <div className={svgIconClassName}>
            {svgIcon}
            {arrowSvg}
          </div>
        </div>

        {/* SVG at the bottom */}
        <div className="w-full relative">{svgIconBottom}</div>
      </div>
    </div>
  );
};

export default InfoCardDashboard;
