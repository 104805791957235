import Api from "../../network/client";

export const createCustomer = async (data) => {
  return Api.post(`supplier`, data);
};
export const updateCustomer = async (data, id) => {
  return Api.put(`supplier/${id}`, data);
};
export const getAllCustomerApi = async () => {
  return Api.get(`supplier`);
};
export const deleteCustmer = async (id) => {
  return Api.delete(`supplier/${id}`);
};
