import Api from "../../network/client";

export const forgerPasswordApi = async (data) => {
  return Api.put(`user/change-password`, data);
};

export const updatedUser = async (data) => {
  return Api.put(`user/me`, data);
};

export const getUser = async (data) => {
  return Api.get(`user/me`, data);
};
