import React, { useEffect, useState } from "react";
import CustomTable from "../../component/shared/customTable";
import CustomModal from "../../component/shared/modal";
import AddCustomer from "../../component/customer/addCustomer";
import { Form, Image, message } from "antd";
import {
  getAllListOfValueByKey,
  getSlugListOfValuesByKey,
} from "../../services/globalService";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";
import {
  createCustomer,
  deleteCustmer,
  updateCustomer,
} from "../../services/customer";

function Customer() {
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gender, setGender] = useState([]);
  const [tier, setTier] = useState([]);
  const [tag, setTag] = useState([]);
  const [id, setId] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);

  const [form] = Form.useForm();

  const fetchListOfValues = async () => {
    try {
      const { data: response } =
        await getSlugListOfValuesByKey("customer-tier");
      setTier(response.data.data);
      const { data: tagData } = await getSlugListOfValuesByKey("customer-tag");
      setTag(tagData.data.data);
    } catch {
      return ServerError();
    }
  };

  const getListOfValues = async () => {
    try {
      const response = await getAllListOfValueByKey("gender");
      setGender(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getListOfValues();
  }, []);

  useEffect(() => {
    fetchListOfValues();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onClickHandle = (row) => {
    setId(row._id);
    form.setFieldsValue(row);
    setIsModalOpen(true);
  };

  const columns = [
    // {
    //   title: "Image",
    //   dataIndex: "image",
    //   key: "image",
    //   render: (_, row) => {
    //     return (
    //       <Image
    //         height={"50px"}
    //         width={"50px"}
    //         style={{ borderRadius: "50%" }}
    //         src={row?.logo ? row?.logo : ""}
    //       />
    //     );
    //   },
    // },
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
  ];

  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteCustmer(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onFinish = async (values) => {
    let response;

    try {
      const payload = {
        ...values,
        companyTaxId: "str",
        logo: "http",
        accountLimit: "0",
        code: "code",
        isActive: true,
      };
      if (id) {
        response = await updateCustomer(payload, id);
      } else {
        response = await createCustomer(payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Successfully added");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };
  const onClickAdd = () => {
    openModal();
    form.resetFields();
  };
  const onClickStatus = async (row) => {
    const payload = {
      ...row,
      isActive: !row.isActive,
    };
    try {
      const response = await updateCustomer(payload, row._id);
      if (
        response.data.meta.status == 201 ||
        response.data.meta.status == 200
      ) {
        message.success(`Successfully Updated`);
        setRefreshTable((prev) => !prev);
      }
    } catch (err) {
      message.success(`Error occured`);
      console.log(err);
    }
  };
  return (
    <main className="mt-6">
      <div className="mt-8 px-6 py-8 bg-white rounded-3xl">
        <div className="flex items-center justify-between mb-8">
          <h2 className="text-2xl font-medium">Customer</h2>
          <div className="flex items-center justify-end gap-6">
            {/* <a href="#" className="block text-lightGrey">
              Import
            </a>
            <a href="#" className="inline-flex items-center text-lightGrey">
              Export
              <svg
                className="ml-2"
                width={13}
                height={7}
                viewBox="0 0 13 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.07038 7.73942C7.0785 7.72982 7.08175 7.71702 7.08987 7.70742L12.7624 1.53604C13.0792 1.18883 13.0792 0.625618 12.7624 0.278408C12.7591 0.275208 12.7559 0.273608 12.7526 0.272008C12.6821 0.187934 12.5938 0.120033 12.4939 0.0730505C12.394 0.0260679 12.2849 0.00113714 12.1742 0H0.827556C0.714808 0.00179399 0.603836 0.0279444 0.502478 0.0766044C0.40112 0.125264 0.311849 0.195247 0.240971 0.281608L0.237722 0.278408C0.084381 0.45311 0 0.676313 0 0.907226C0 1.13814 0.084381 1.36134 0.237722 1.53604L5.92321 7.73942C5.99399 7.82112 6.08192 7.88673 6.18095 7.93172C6.27997 7.9767 6.38773 8 6.49679 8C6.60585 8 6.71361 7.9767 6.81263 7.93172C6.91166 7.88673 6.9996 7.82112 7.07038 7.73942Z"
                  fill="#BABABA"
                />
              </svg>
            </a> */}
            <a
              onClick={onClickAdd}
              className="block capitalize py-4 px-6 min-w-36 text-center rounded-full leading-none text-bold bg-redOrange text-white"
            >
              Add Customer
            </a>

            <CustomModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              heading={`Add Customer`}
            >
              <AddCustomer
                onFinish={onFinish}
                form={form}
                tier={tier}
                tag={tag}
                gender={gender}
              />
            </CustomModal>
          </div>
        </div>
        <form action="" className="relative mb-6">
          <input
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Find Customer"
            className="block leading-none py-3 pr-10 pl-7 border w-full rounded-full"
          />
          <div className="overlay hidden fixed top-0 bottom-0 left-0 w-full h-100 bg-black z-10 opacity-35" />
        </form>
        {/* Records */}
        <div className="flex flex-col justify-between min-h-96">
          <CustomTable
            columns={columns}
            endPoint={"supplier"}
            search={searchText}
            handleEdit={onClickHandle}
            handleDelete={onClickDelete}
            handleStatus={onClickStatus}
            showActions={true}
            refreshTable={refreshTable}
          />
        </div>
      </div>
    </main>
  );
}

export default Customer;
