import React from "react";

function Filter() {
  return (
    <>
      <label htmlFor="" className="block flex-1">
        <span className="text-lightGrey text-sm">Product Type</span>
        <div className="relative">
          <select
            name=""
            id=""
            className="block cursor-pointer appearance-none leading-1 py-2.5 px-4 border border-lightGrey text-lightGrey w-full rounded-full mt-2 outline-0"
          >
            <option value="">All</option>
            <option value={1}>1</option>
          </select>
          <svg
            className="absolute top-2/4 -translate-y-2/4 right-5 pointer-events-none"
            width={13}
            height={7}
            viewBox="0 0 13 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.07038 7.73942C7.0785 7.72982 7.08175 7.71702 7.08987 7.70742L12.7624 1.53604C13.0792 1.18883 13.0792 0.625618 12.7624 0.278408C12.7591 0.275208 12.7559 0.273608 12.7526 0.272008C12.6821 0.187934 12.5938 0.120033 12.4939 0.0730505C12.394 0.0260679 12.2849 0.00113714 12.1742 0H0.827556C0.714808 0.00179399 0.603836 0.0279444 0.502478 0.0766044C0.40112 0.125264 0.311849 0.195247 0.240971 0.281608L0.237722 0.278408C0.084381 0.45311 0 0.676313 0 0.907226C0 1.13814 0.084381 1.36134 0.237722 1.53604L5.92321 7.73942C5.99399 7.82112 6.08192 7.88673 6.18095 7.93172C6.27997 7.9767 6.38773 8 6.49679 8C6.60585 8 6.71361 7.9767 6.81263 7.93172C6.91166 7.88673 6.9996 7.82112 7.07038 7.73942Z"
              fill="#BABABA"
            />
          </svg>
        </div>
      </label>
      <label htmlFor="" className="block flex-1">
        <span className="text-lightGrey text-sm">Inventory</span>
        <div className="relative">
          <select
            name=""
            id=""
            className="block cursor-pointer appearance-none leading-1 py-2.5 px-4 border border-lightGrey text-lightGrey w-full rounded-full mt-2 outline-0"
          >
            <option value="">All</option>
            <option value={1}>1</option>
          </select>
          <svg
            className="absolute top-2/4 -translate-y-2/4 right-5 pointer-events-none"
            width={13}
            height={7}
            viewBox="0 0 13 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.07038 7.73942C7.0785 7.72982 7.08175 7.71702 7.08987 7.70742L12.7624 1.53604C13.0792 1.18883 13.0792 0.625618 12.7624 0.278408C12.7591 0.275208 12.7559 0.273608 12.7526 0.272008C12.6821 0.187934 12.5938 0.120033 12.4939 0.0730505C12.394 0.0260679 12.2849 0.00113714 12.1742 0H0.827556C0.714808 0.00179399 0.603836 0.0279444 0.502478 0.0766044C0.40112 0.125264 0.311849 0.195247 0.240971 0.281608L0.237722 0.278408C0.084381 0.45311 0 0.676313 0 0.907226C0 1.13814 0.084381 1.36134 0.237722 1.53604L5.92321 7.73942C5.99399 7.82112 6.08192 7.88673 6.18095 7.93172C6.27997 7.9767 6.38773 8 6.49679 8C6.60585 8 6.71361 7.9767 6.81263 7.93172C6.91166 7.88673 6.9996 7.82112 7.07038 7.73942Z"
              fill="#BABABA"
            />
          </svg>
        </div>
      </label>
      <label htmlFor="" className="block flex-1">
        <span className="text-lightGrey text-sm">Status</span>
        <div className="relative">
          <select
            name=""
            id=""
            className="block cursor-pointer appearance-none leading-1 py-2.5 px-4 border border-lightGrey text-lightGrey w-full rounded-full mt-2 outline-0"
          >
            <option value="">All</option>
            <option value={1}>1</option>
          </select>
          <svg
            className="absolute top-2/4 -translate-y-2/4 right-5 pointer-events-none"
            width={13}
            height={7}
            viewBox="0 0 13 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.07038 7.73942C7.0785 7.72982 7.08175 7.71702 7.08987 7.70742L12.7624 1.53604C13.0792 1.18883 13.0792 0.625618 12.7624 0.278408C12.7591 0.275208 12.7559 0.273608 12.7526 0.272008C12.6821 0.187934 12.5938 0.120033 12.4939 0.0730505C12.394 0.0260679 12.2849 0.00113714 12.1742 0H0.827556C0.714808 0.00179399 0.603836 0.0279444 0.502478 0.0766044C0.40112 0.125264 0.311849 0.195247 0.240971 0.281608L0.237722 0.278408C0.084381 0.45311 0 0.676313 0 0.907226C0 1.13814 0.084381 1.36134 0.237722 1.53604L5.92321 7.73942C5.99399 7.82112 6.08192 7.88673 6.18095 7.93172C6.27997 7.9767 6.38773 8 6.49679 8C6.60585 8 6.71361 7.9767 6.81263 7.93172C6.91166 7.88673 6.9996 7.82112 7.07038 7.73942Z"
              fill="#BABABA"
            />
          </svg>
        </div>
      </label>
      <label htmlFor="" className="block flex-1">
        <span className="text-lightGrey text-sm">Sales Channels</span>
        <div className="relative">
          <select
            name=""
            id=""
            className="block cursor-pointer appearance-none leading-1 py-2.5 px-4 border border-lightGrey text-lightGrey w-full rounded-full mt-2 outline-0"
          >
            <option value="">All</option>
            <option value={1}>1</option>
          </select>
          <svg
            className="absolute top-2/4 -translate-y-2/4 right-5 pointer-events-none"
            width={13}
            height={7}
            viewBox="0 0 13 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.07038 7.73942C7.0785 7.72982 7.08175 7.71702 7.08987 7.70742L12.7624 1.53604C13.0792 1.18883 13.0792 0.625618 12.7624 0.278408C12.7591 0.275208 12.7559 0.273608 12.7526 0.272008C12.6821 0.187934 12.5938 0.120033 12.4939 0.0730505C12.394 0.0260679 12.2849 0.00113714 12.1742 0H0.827556C0.714808 0.00179399 0.603836 0.0279444 0.502478 0.0766044C0.40112 0.125264 0.311849 0.195247 0.240971 0.281608L0.237722 0.278408C0.084381 0.45311 0 0.676313 0 0.907226C0 1.13814 0.084381 1.36134 0.237722 1.53604L5.92321 7.73942C5.99399 7.82112 6.08192 7.88673 6.18095 7.93172C6.27997 7.9767 6.38773 8 6.49679 8C6.60585 8 6.71361 7.9767 6.81263 7.93172C6.91166 7.88673 6.9996 7.82112 7.07038 7.73942Z"
              fill="#BABABA"
            />
          </svg>
        </div>
      </label>
      <label htmlFor="" className="block flex-1">
        <span className="text-lightGrey text-sm">Sales Volume</span>
        <div className="relative">
          <select
            name=""
            id=""
            className="block cursor-pointer appearance-none leading-1 py-2.5 px-4 border border-lightGrey text-lightGrey w-full rounded-full mt-2 outline-0"
          >
            <option value="">All</option>
            <option value={1}>1</option>
          </select>
          <svg
            className="absolute top-2/4 -translate-y-2/4 right-5 pointer-events-none"
            width={13}
            height={7}
            viewBox="0 0 13 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.07038 7.73942C7.0785 7.72982 7.08175 7.71702 7.08987 7.70742L12.7624 1.53604C13.0792 1.18883 13.0792 0.625618 12.7624 0.278408C12.7591 0.275208 12.7559 0.273608 12.7526 0.272008C12.6821 0.187934 12.5938 0.120033 12.4939 0.0730505C12.394 0.0260679 12.2849 0.00113714 12.1742 0H0.827556C0.714808 0.00179399 0.603836 0.0279444 0.502478 0.0766044C0.40112 0.125264 0.311849 0.195247 0.240971 0.281608L0.237722 0.278408C0.084381 0.45311 0 0.676313 0 0.907226C0 1.13814 0.084381 1.36134 0.237722 1.53604L5.92321 7.73942C5.99399 7.82112 6.08192 7.88673 6.18095 7.93172C6.27997 7.9767 6.38773 8 6.49679 8C6.60585 8 6.71361 7.9767 6.81263 7.93172C6.91166 7.88673 6.9996 7.82112 7.07038 7.73942Z"
              fill="#BABABA"
            />
          </svg>
        </div>
      </label>
    </>
  );
}

export default Filter;
