import React, { useEffect, useState } from "react";
import BasicCard from "../../component/shared/basicCard/basicCard";
import { Form } from "antd";
import {
  createProduct,
  getProductById,
  updateProduct,
} from "../../services/product";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllOutletApi } from "../../services/outlet";
import { getAllCustomerApi } from "../../services/customer";
import Information from "../../component/purchase/information";
import ComponentProduct from "../../component/purchase/productForm";

const variants = [
  {
    combination: [],
    sku: "",
    barcode: "",
    costPrice: 0,
    retailPrice: 0,
    retailPriceInclTax: 0,
    tax: 0,
    taxId: "",
    discount: 0,
    discountTypeId: null,
  },
];
function AddPurchase() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState(location.state?.id ? location?.state?.id : null);
  const [outlet, setOutlet] = useState(null);
  const [customer, setCustomer] = useState(null);

  const fetchProductDataByIds = async (ids) => {
    try {
      const promises = ids.map((id) => getProductById(id)); // Assuming getProductById is your API function
      const results = await Promise.all(promises);
      // Now you have an array of product data
      const productData = results.map((result) => ({
        value: result.data.data._id,
        label: result.data.data.name,
        ...result.data.data,
      }));

      // Set the product data in the form
      form.setFieldValue("compositeProductsIds", productData);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  };
  const getProduct = async (id) => {
    try {
      const {
        data: { data },
      } = await getProductById(id);
      if (data) {
        if (data.type == "COMPOSITE_PRODUCT") {
          const compositeProductsIds = data.compositeProducts.map(
            (val) => val.productId,
          );
          fetchProductDataByIds(compositeProductsIds);
          // form.setFieldValue('compositeProductsIds', compositeProductsIds);
          form.setFieldValue("variants", {
            retailPrice: data?.variants[0]?.retailPrice,
            retailPriceInclTax: data?.variants[0]?.retailPriceInclTax,
            taxId: data?.variants[0]?.taxId,
            discountTypeId: null,
          });
          form.setFieldValue("retailPrice", data?.variants[0]?.retailPrice);
          form.setFieldValue("taxId", data?.variants[0]?.taxId);
          form.setFieldValue(
            "retailPriceInclTax",
            data?.variants[0]?.retailPriceInclTax,
          );
        }
        // setProduct(data);
        // setSelectedProduct(data.type);
        form.setFieldsValue(data);
      }
    } catch {
      ServerError();
    }
  };

  useEffect(() => {
    if (id) {
      getProduct(id);
    } else {
      form.setFieldsValue({ variants: variants });
    }
  }, []);

  const getListOfValues = async () => {
    try {
      // Call all APIs simultaneously using Promise.all
      const [getAllOutletRes, customerRes] = await Promise.all([
        getAllOutletApi(),
        getAllCustomerApi(),
      ]);
      setOutlet(getAllOutletRes.data.data.data);
      setCustomer(customerRes.data.data.data);
    } catch (err) {
      console.error("Error fetching data", err);
    }
  };

  // Call the API fetching function on component mount
  useEffect(() => {
    getListOfValues();
  }, []);

  const onFinish = async (values) => {
    let response;
    let payload;
    try {
      payload = {
        ...values,
        isActive: true,
      };
      if (id) {
        response = await updateProduct(payload, id);
      } else {
        response = await createProduct(payload);
      }
      if (response.data.meta.success) {
        form.resetFields();
        openNotification("success", "Product added successfully");
        navigate("../products");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };

  return (
    <main className="mt-6 text-left">
      <Form
        form={form}
        name="descriptionForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="grid gap-8 grid-cols-12 pb-24">
          <div className="col-span-12">
            <BasicCard heading={"Add Purchase"} className="addProductInfo">
              <Information outlet={outlet} customer={customer} />
            </BasicCard>

            <BasicCard heading={"Composite Product"}>
              <ComponentProduct form={form} />
            </BasicCard>
          </div>
          <div className="col-span-12">
            <div className="flex items-center justify-end gap-3">
              <Form.Item>
                <input
                  type="button"
                  onClick={() => navigate("../purchase")}
                  defaultValue="Cancel"
                  className="leading-none block py-4 px-10 bg-veryLightGrey rounded-full text-lightGrey font-medium border border-lightGrey"
                />
              </Form.Item>
              <Form.Item>
                <input
                  type="submit"
                  defaultValue="Save"
                  className="leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium border border-redOrange"
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </main>
  );
}

export default AddPurchase;
