import React, { useState } from "react";
import CustomModal from "../../shared/modal";
import CashInModal from "./modal/cashIn";
import { cashInnOutApi } from "../../../services/register";
import { openNotification, ServerError } from "../../shared/notification";

function CashInOut({ registerData, registerId }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCashOut, setIsModalOpenCashOut] = useState(false);
  const [actionTxt, setActionTxt] = useState("");
  const { transaction, createdBy } = registerData || {};
  const openClickOpenModal = (action) => {
    const actionLowercasr = action.toLowerCase();
    if (actionLowercasr == "in") {
      setIsModalOpen(true);
      setActionTxt("In");
    } else {
      setIsModalOpenCashOut(true);
      setActionTxt("Out");
    }
  };
  const onFinishCashInAndOut = async (values, actionType) => {
    if (actionType == "in") {
      let response;
      try {
        const payload = {
          ...values,
        };
        response = await cashInnOutApi(
          registerId,
          registerData._id,
          "IN",
          payload,
        );
        if (response.data.meta.success) {
          openNotification("success", "Record Added");
        } else {
          openNotification("error", response.data.error);
        }
      } catch (err) {
        console.log(err);
        ServerError();
      } finally {
        setIsModalOpen(false);
      }
    } else if (actionType == "out") {
      let response;
      try {
        const payload = {
          ...values,
        };
        response = await cashInnOutApi(
          registerId,
          registerData._id,
          "OUT",
          payload,
        );
        if (response.data.meta.success) {
          openNotification("success", "Record Added");
        } else {
          openNotification("error", response.data.error);
        }
      } catch (err) {
        console.log(err);
        ServerError();
      } finally {
        setIsModalOpenCashOut(false);
      }
    }
  };
  return (
    <>
      <div className="flex items-center justify-end gap-2 py-4">
        <div
          id="cashInBtn"
          className="flex items-center justify-center bg-brightUFOGreen leading-none min-w-16 py-1.5 px-1 border border-ufoGreen rounded-full"
        >
          <div onClick={() => openClickOpenModal("in")} className="flex">
            <div className="mr-1">
              <svg
                width={20}
                height={13}
                viewBox="0 0 20 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.397 8.90625C16.5473 8.90625 15.7325 9.24377 15.1317 9.84456C14.5309 10.4454 14.1934 11.2602 14.1934 12.1099"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.1934 2.60156C14.1934 3.45121 14.5309 4.26606 15.1317 4.86685C15.7325 5.46765 16.5473 5.80517 17.397 5.80517"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 5.80541C1.84965 5.80541 2.6645 5.46789 3.26529 4.8671C3.86608 4.2663 4.2036 3.45146 4.2036 2.60181"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.2036 12.1099C4.2036 11.6892 4.12074 11.2726 3.95975 10.8839C3.79875 10.4952 3.56277 10.142 3.26529 9.84456C2.96781 9.54708 2.61465 9.31111 2.22597 9.15011C1.83729 8.98911 1.4207 8.90625 1 8.90625"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 2.60181H17.3968V12.1095H1V2.60181Z"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4226 6.13171C10.4226 5.88964 10.3508 5.65301 10.2163 5.45173C10.0819 5.25046 9.89072 5.09357 9.66709 5.00092C9.44346 4.90827 9.19737 4.88401 8.95995 4.93121C8.72253 4.9784 8.50443 5.09494 8.33324 5.26608C8.16205 5.43722 8.04544 5.65528 7.99817 5.89269C7.9509 6.13009 7.97509 6.37619 8.06767 6.59985C8.16026 6.82351 8.31708 7.0147 8.51832 7.14924C8.71955 7.28378 8.95616 7.35563 9.19823 7.3557"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.97412 8.57995C7.97412 8.82203 8.04591 9.05868 8.1804 9.25996C8.31489 9.46125 8.50606 9.61813 8.72971 9.71077C8.95337 9.80341 9.19947 9.82765 9.4369 9.78042C9.67433 9.7332 9.89243 9.61662 10.0636 9.44544C10.2348 9.27426 10.3514 9.05617 10.3986 8.81874C10.4458 8.58131 10.4216 8.3352 10.3289 8.11155C10.2363 7.88789 10.0794 7.69673 9.87813 7.56224C9.67684 7.42774 9.4402 7.35596 9.19811 7.35596"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.19824 4.41675V10.2952"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.3978 10.5066H18.9998V1H2.60303V2.60199"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.397 8.90625C16.5473 8.90625 15.7325 9.24377 15.1317 9.84456C14.5309 10.4454 14.1934 11.2602 14.1934 12.1099"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.1934 2.60156C14.1934 3.45121 14.5309 4.26606 15.1317 4.86685C15.7325 5.46765 16.5473 5.80517 17.397 5.80517"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 5.80541C1.84965 5.80541 2.6645 5.46789 3.26529 4.8671C3.86608 4.2663 4.2036 3.45146 4.2036 2.60181"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.2036 12.1099C4.2036 11.6892 4.12074 11.2726 3.95975 10.8839C3.79875 10.4952 3.56277 10.142 3.26529 9.84456C2.96781 9.54708 2.61465 9.31111 2.22597 9.15011C1.83729 8.98911 1.4207 8.90625 1 8.90625"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 2.60181H17.3968V12.1095H1V2.60181Z"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4226 6.13171C10.4226 5.88964 10.3508 5.65301 10.2163 5.45173C10.0819 5.25046 9.89072 5.09357 9.66709 5.00092C9.44346 4.90827 9.19737 4.88401 8.95995 4.93121C8.72253 4.9784 8.50443 5.09494 8.33324 5.26608C8.16205 5.43722 8.04544 5.65528 7.99817 5.89269C7.9509 6.13009 7.97509 6.37619 8.06767 6.59985C8.16026 6.82351 8.31708 7.0147 8.51832 7.14924C8.71955 7.28378 8.95616 7.35563 9.19823 7.3557"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.97412 8.57995C7.97412 8.82203 8.04591 9.05868 8.1804 9.25996C8.31489 9.46125 8.50606 9.61813 8.72971 9.71077C8.95337 9.80341 9.19947 9.82765 9.4369 9.78042C9.67433 9.7332 9.89243 9.61662 10.0636 9.44544C10.2348 9.27426 10.3514 9.05617 10.3986 8.81874C10.4458 8.58131 10.4216 8.3352 10.3289 8.11155C10.2363 7.88789 10.0794 7.69673 9.87813 7.56224C9.67684 7.42774 9.4402 7.35596 9.19811 7.35596"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.19824 4.41675V10.2952"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.3978 10.5068H18.9998V1.00024H2.60303V2.60223"
                  stroke="#00E37D"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <span className="block text-ufoGreen text-[0.75rem] font-medium">
              IN
            </span>
          </div>
          <div className="overlay hidden fixed top-0 bottom-0 left-0 w-full h-100 bg-black z-10 opacity-35" />
          <CustomModal
            isModalOpen={isModalOpen}
            closeModal={() => setIsModalOpen(false)}
            heading={`Cash ${actionTxt}`}
          >
            <CashInModal
              action={actionTxt}
              onFinishCashInAndOut={onFinishCashInAndOut}
            />
          </CustomModal>
        </div>
        <div
          id="cashOutBtn"
          className="flex items-center justify-center bg-brightRedOrange leading-none min-w-16 py-1.5 px-1 border border-redOrange rounded-full"
        >
          <div onClick={() => openClickOpenModal("out")} className="flex">
            <div className="mr-1">
              <svg
                width={20}
                height={13}
                viewBox="0 0 20 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.397 8.90625C16.5473 8.90625 15.7325 9.24377 15.1317 9.84456C14.5309 10.4454 14.1934 11.2602 14.1934 12.1099"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.1934 2.60156C14.1934 3.45121 14.5309 4.26606 15.1317 4.86685C15.7325 5.46765 16.5473 5.80517 17.397 5.80517"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 5.80541C1.84965 5.80541 2.6645 5.46789 3.26529 4.8671C3.86608 4.2663 4.2036 3.45146 4.2036 2.60181"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.2036 12.1099C4.2036 11.6892 4.12074 11.2726 3.95975 10.8839C3.79875 10.4952 3.56277 10.142 3.26529 9.84456C2.96781 9.54708 2.61465 9.31111 2.22597 9.15011C1.83729 8.98911 1.4207 8.90625 1 8.90625"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 2.60181H17.3968V12.1095H1V2.60181Z"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4226 6.13171C10.4226 5.88964 10.3508 5.65301 10.2163 5.45173C10.0819 5.25046 9.89072 5.09357 9.66709 5.00092C9.44346 4.90827 9.19737 4.88401 8.95995 4.93121C8.72253 4.9784 8.50443 5.09494 8.33324 5.26608C8.16205 5.43722 8.04544 5.65528 7.99817 5.89269C7.9509 6.13009 7.97509 6.37619 8.06767 6.59985C8.16026 6.82351 8.31708 7.0147 8.51832 7.14924C8.71955 7.28378 8.95616 7.35563 9.19823 7.3557"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.97412 8.57995C7.97412 8.82203 8.04591 9.05868 8.1804 9.25996C8.31489 9.46125 8.50606 9.61813 8.72971 9.71077C8.95337 9.80341 9.19947 9.82765 9.4369 9.78042C9.67433 9.7332 9.89243 9.61662 10.0636 9.44544C10.2348 9.27426 10.3514 9.05617 10.3986 8.81874C10.4458 8.58131 10.4216 8.3352 10.3289 8.11155C10.2363 7.88789 10.0794 7.69673 9.87813 7.56224C9.67684 7.42774 9.4402 7.35596 9.19811 7.35596"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.19824 4.41675V10.2952"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.3978 10.5066H18.9998V1H2.60303V2.60199"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.397 8.90625C16.5473 8.90625 15.7325 9.24377 15.1317 9.84456C14.5309 10.4454 14.1934 11.2602 14.1934 12.1099"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.1934 2.60156C14.1934 3.45121 14.5309 4.26606 15.1317 4.86685C15.7325 5.46765 16.5473 5.80517 17.397 5.80517"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 5.80541C1.84965 5.80541 2.6645 5.46789 3.26529 4.8671C3.86608 4.2663 4.2036 3.45146 4.2036 2.60181"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M4.2036 12.1099C4.2036 11.6892 4.12074 11.2726 3.95975 10.8839C3.79875 10.4952 3.56277 10.142 3.26529 9.84456C2.96781 9.54708 2.61465 9.31111 2.22597 9.15011C1.83729 8.98911 1.4207 8.90625 1 8.90625"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 2.60181H17.3968V12.1095H1V2.60181Z"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.4226 6.13171C10.4226 5.88964 10.3508 5.65301 10.2163 5.45173C10.0819 5.25046 9.89072 5.09357 9.66709 5.00092C9.44346 4.90827 9.19737 4.88401 8.95995 4.93121C8.72253 4.9784 8.50443 5.09494 8.33324 5.26608C8.16205 5.43722 8.04544 5.65528 7.99817 5.89269C7.9509 6.13009 7.97509 6.37619 8.06767 6.59985C8.16026 6.82351 8.31708 7.0147 8.51832 7.14924C8.71955 7.28378 8.95616 7.35563 9.19823 7.3557"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M7.97412 8.57995C7.97412 8.82203 8.04591 9.05868 8.1804 9.25996C8.31489 9.46125 8.50606 9.61813 8.72971 9.71077C8.95337 9.80341 9.19947 9.82765 9.4369 9.78042C9.67433 9.7332 9.89243 9.61662 10.0636 9.44544C10.2348 9.27426 10.3514 9.05617 10.3986 8.81874C10.4458 8.58131 10.4216 8.3352 10.3289 8.11155C10.2363 7.88789 10.0794 7.69673 9.87813 7.56224C9.67684 7.42774 9.4402 7.35596 9.19811 7.35596"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.19824 4.41675V10.2952"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M17.3978 10.5068H18.9998V1.00024H2.60303V2.60223"
                  stroke="#FE5E5E"
                  strokeWidth="0.8"
                  strokeMiterlimit={10}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <span className="block text-redOrange text-[0.75rem] font-medium">
              OUT
            </span>
          </div>

          <div className="overlay hidden fixed top-0 bottom-0 left-0 w-full h-100 bg-black z-10 opacity-35" />
          <CustomModal
            isModalOpen={isModalOpenCashOut}
            closeModal={() => setIsModalOpenCashOut(false)}
            heading={`Cash ${actionTxt}`}
          >
            <CashInModal
              action={actionTxt}
              onFinishCashInAndOut={onFinishCashInAndOut}
            />
          </CustomModal>
        </div>
      </div>
      <table className="w-full">
        <thead>
          <tr>
            <th className="font-medium text-sm py-2 border-b border-lightBlue text-left">
              Transaction
            </th>
            <th className="font-medium text-sm py-2 border-b border-lightBlue text-left">
              User
            </th>
            <th className="font-medium text-sm py-2 border-b border-lightBlue text-right">
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              Opening Float
            </td>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              {createdBy?.fullName || "N/A"}
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {transaction?.openingBalance || 0}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default CashInOut;
