import React, { useState } from "react";
import HeroTab from "../../settings/tabs/landing/hero";

const TabsComponent = ({ tabData }) => {
  const [activeTab, setActiveTab] = useState(tabData[0].name);
  return (
    <div className="grid gap-8 grid-cols-12 pb-12">
      <div className="col-span-3">
        <div className="bg-white rounded-2xl">
          <ul className="p-4">
            {tabData.map((tab) => {
              return (
                <li
                  className={`group mb-0.5 last:mb-0 ${activeTab === tab.name ? "active" : ""}`}
                >
                  <a
                    href="#"
                    onClick={() => setActiveTab(tab.name)}
                    className="flex items-center justify-start py-2.5 px-3 leading-none rounded-lg text-md font-medium text-lightGrey group-[&.active]:bg-redOrange group-[&.active]:text-white transition hover:text-black"
                  >
                    {tab.icon}
                    {tab.name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {tabData
        .filter((tab) => tab.name === activeTab)
        .map((tab, index) => {
          return (
            <div key={index} className="col-span-9">
              {tab.component}
            </div>
          );
        })}
    </div>
  );
};

export default TabsComponent;
