import { Checkbox, Form, Input } from "antd";
import React from "react";
import CustomSelect from "../../shared/customSelect/indes";
import { dropDownPayload, dropDownPayloadLov } from "../../../utils/helper";

function Information({ unit, barcodeTypes }) {
  return (
    <>
      <Form.Item
        name="name"
        rules={[{ required: true, message: "Please input the product name!" }]}
      >
        <Input
          placeholder="Product Name"
          className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full mt-6 outline-0"
        />
      </Form.Item>
      {/* SKU and Barcode Fields */}
      <div className="grid grid-cols-3 gap-5">
        <Form.Item
          name="skuId"
          rules={[{ required: true, message: "Please input the SKU!" }]}
        >
          <CustomSelect
            options={dropDownPayload(unit)}
            placeholder={"Select Unit"}
          />
          {/* unit */}
        </Form.Item>

        <Form.Item
          name="barcodeTypeId"
          rules={[
            { required: true, message: "Please input the barcode type!" },
          ]}
        >
          <CustomSelect
            options={dropDownPayloadLov(barcodeTypes)}
            placeholder={"Select Barcode Type"}
          />
          {/* unit */}
        </Form.Item>

        <Form.Item
          name="barcode"
          rules={[{ required: true, message: "Please input the barcode!" }]}
        >
          <Input
            placeholder="Barcode"
            className="block leading-none py-3 px-6 mb-0 border border-lightGrey w-full rounded-full outline-0"
          />
        </Form.Item>
      </div>
      <div className="">
        <Form.Item
          name="description"
          label={<span className="text-sm text-lightGrey">Description</span>}
          rules={[
            {
              required: true,
              message: "Please enter a description",
            },
          ]}
        >
          <Input.TextArea
            rows={5}
            className="border border-lightGrey rounded-2xl resize-none py-3 px-5"
            placeholder="Enter your description here"
          />
        </Form.Item>
      </div>
    </>
  );
}

export default Information;
