import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, Row, Col, message, Select } from "antd";
import CustomSelect from "../shared/customSelect/indes";
import { dropDownPayload } from "../../utils/helper";
import { getAllOutletApi } from "../../services/outlet";

function AddCashRegister({ form, onFinish }) {
  const [outlet, setOutlet] = useState([]);
  const [loading, setLoading] = useState(false);

  const getOutlet = async () => {
    try {
      const { data: response } = await getAllOutletApi();
      const { data: record } = response;
      setOutlet(record.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getOutlet();
  }, []);

  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={<span className="text-lightGrey font-medium">Name</span>}
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter First name"
              />
            </Form.Item>

            <Form.Item
              name="receiptNo"
              label={
                <span className="text-lightGrey font-medium">Receipt No</span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                type="number"
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter Receipt No"
              />
            </Form.Item>

            <Form.Item
              name="receiptPrefix"
              label={
                <span className="text-lightGrey font-medium">
                  Receipt Prefix
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter Receipt Prefix"
              />
            </Form.Item>

            <Form.Item
              name="receiptSuffix"
              label={
                <span className="text-lightGrey font-medium">
                  Receipt Suffix
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter Receipt Suffix"
              />
            </Form.Item>

            <Form.Item
              name="outletId"
              label={
                <span className="text-lightGrey font-medium">
                  Select Outlet{" "}
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <CustomSelect
                options={dropDownPayload(outlet)}
                placeholder={"Enter Outlet"}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Submit Button */}
        <div className="flex items-center justify-center gap-3 mt-7">
          <Button
            type="primary"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none block py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AddCashRegister;
