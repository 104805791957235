import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  message,
  Select,
  InputNumber,
  Checkbox,
  TimePicker,
  Switch,
} from "antd";
import moment from "moment";
import { DaysName } from "../shared/contants";

function AddOutlet({ form, onFinish, weekDays }) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        {/* <Row gutter={24}> */}
        {/* Left Side Form Fields */}
        <Row gutter={24}>
          <Col span={12}>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label={
                    <span className="text-lightGrey font-medium">Name</span>
                  }
                  rules={[{ required: true, message: "This is required" }]}
                >
                  <Input
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter Name"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="email"
                  label={
                    <span className="text-lightGrey font-medium">Email</span>
                  }
                  rules={[{ required: true, message: "This is required" }]}
                >
                  <Input
                    type="email"
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter email"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  label={
                    <span className="text-lightGrey font-medium">Phone</span>
                  }
                  rules={[{ required: true, message: "This is required" }]}
                >
                  <Input
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter Phone"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="street"
                  label={
                    <span className="text-lightGrey font-medium">Street</span>
                  }
                  rules={[{ required: true, message: "This is required" }]}
                >
                  <Input
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter Street"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="city"
                  label={
                    <span className="text-lightGrey font-medium">City</span>
                  }
                  rules={[{ required: true, message: "This is required" }]}
                >
                  <Input
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter City"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="state"
                  label={
                    <span className="text-lightGrey font-medium">State</span>
                  }
                  rules={[{ required: true, message: "This is required" }]}
                >
                  <Input
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter State"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="postal"
                  label={
                    <span className="text-lightGrey font-medium">Postal</span>
                  }
                  rules={[{ required: true, message: "This is required" }]}
                >
                  <Input
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter Postal"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="country"
                  label={
                    <span className="text-lightGrey font-medium">Country</span>
                  }
                  rules={[{ required: true, message: "This is required" }]}
                >
                  <Input
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter Country"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name={["purchaseOrderConfig", "type"]}
                  label={
                    <span className="text-lightGrey font-medium">
                      Purchase Order
                    </span>
                  }
                  rules={[{ required: true, message: "Type is required" }]}
                >
                  <InputNumber
                    className="block w-full border focus:border-ufoGreen rounded-full py-2 px-6"
                    placeholder="Enter type"
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={["purchaseOrderConfig", "prefix"]}
                  label={
                    <span className="text-lightGrey font-medium">Prefix</span>
                  }
                  rules={[{ required: true, message: "Prefix is required" }]}
                >
                  <Input
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter prefix"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={["purchaseOrderConfig", "suffix"]}
                  label={
                    <span className="text-lightGrey font-medium">Suffix</span>
                  }
                  rules={[{ required: true, message: "Suffix is required" }]}
                >
                  <Input
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter suffix"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name={["inventoryTransferConfig", "type"]}
                  label={
                    <span className="text-lightGrey font-medium">
                      Inventory Transfer
                    </span>
                  }
                  rules={[{ required: true, message: "Type is required" }]}
                >
                  <InputNumber
                    className="block w-full border focus:border-ufoGreen rounded-full py-2 px-6"
                    placeholder="Enter type"
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={["inventoryTransferConfig", "prefix"]}
                  label={
                    <span className="text-lightGrey font-medium">Prefix</span>
                  }
                  rules={[{ required: true, message: "Prefix is required" }]}
                >
                  <Input
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter prefix"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={["inventoryTransferConfig", "suffix"]}
                  label={
                    <span className="text-lightGrey font-medium">Suffix</span>
                  }
                  rules={[{ required: true, message: "Suffix is required" }]}
                >
                  <Input
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter suffix"
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={24}>
              <Col span={8}>
                <Form.Item
                  name={["inventoryCountConfig", "type"]}
                  label={
                    <span className="text-lightGrey font-medium">
                      Inventory Count
                    </span>
                  }
                  rules={[{ required: true, message: "Type is required" }]}
                >
                  <InputNumber
                    className="block w-full border focus:border-ufoGreen rounded-full py-2 px-6"
                    placeholder="Enter type"
                    min={0}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={["inventoryCountConfig", "prefix"]}
                  label={
                    <span className="text-lightGrey font-medium">Prefix</span>
                  }
                  rules={[{ required: true, message: "Prefix is required" }]}
                >
                  <Input
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter prefix"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={["inventoryCountConfig", "suffix"]}
                  label={
                    <span className="text-lightGrey font-medium">Suffix</span>
                  }
                  rules={[{ required: true, message: "Suffix is required" }]}
                >
                  <Input
                    className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                    placeholder="Enter suffix"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <table className="tbl-hour w-full border">
              <tr>
                <th className="border-b py-2 px-2">
                  <span>Day</span>
                </th>
                <th className="border-b py-2 px-2">
                  <span>Open/Close</span>
                </th>
                <th className="border-b py-2 px-2">
                  <span>Start Time</span>
                </th>
                <th className="border-b py-2 px-2">
                  <span>End Time</span>
                </th>
              </tr>
              <Form.List name="openingHours">
                {(fields) => (
                  <>
                    {fields.map(
                      ({ key, name, fieldKey, ...restField }, index) => (
                        <tr>
                          <td className="px-2 py-3 align-middle">
                            {DaysName[index]}
                          </td>
                          <td className="px-2 py-3">
                            <Form.Item
                              {...restField}
                              name={[name, "isOpen"]}
                              fieldKey={[fieldKey, "isOpen"]}
                              valuePropName="checked"
                            >
                              <Switch />
                            </Form.Item>
                          </td>
                          <td className="px-2 py-3">
                            <Form.Item
                              {...restField}
                              name={[name, "startTime"]}
                              fieldKey={[fieldKey, "startTime"]}
                            >
                              <Input type="time" />
                            </Form.Item>
                          </td>
                          <td className="px-2 py-3">
                            <Form.Item
                              {...restField}
                              name={[name, "endTime"]}
                              fieldKey={[fieldKey, "endTime"]}
                            >
                              <Input type="time" />
                            </Form.Item>
                          </td>
                        </tr>
                      ),
                    )}
                  </>
                )}
              </Form.List>
            </table>
            <div className="flex items-center justify-center gap-3 mt-7">
              <Button
                type="primary"
                htmlType="submit"
                className="h-auto cursor-pointer leading-none block py-4 px-12 ml-auto bg-redOrange rounded-full text-white font-medium"
              >
                Save
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default AddOutlet;
