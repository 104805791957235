import { Form, Input } from "antd";
import React from "react";
import CustomSelect from "../shared/customSelect/indes";
import { dropDownPayload, dropDownPayloadLov } from "../../utils/helper";

function Information({ outlet, customer }) {
  return (
    <>
      <div className="grid grid-cols-4 gap-5">
        <Form.Item
          name="purchaseDate"
          rules={[
            { required: true, message: "Please input the Purchase date!" },
          ]}
        >
          <Input
            type="date"
            placeholder="Purchase Date"
            className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full mt-6 outline-0"
          />
        </Form.Item>
      </div>
      {/* SKU and Barcode Fields */}
      <div className="grid grid-cols-2 gap-5">
        <Form.Item
          name="customerId"
          rules={[{ required: true, message: "Please input the customer!" }]}
        >
          <CustomSelect
            options={dropDownPayload(customer)}
            placeholder={"Select customer"}
          />
          {/* unit */}
        </Form.Item>

        <Form.Item
          name="outletId"
          rules={[{ required: true, message: "Please input the outlet!" }]}
        >
          <CustomSelect
            options={dropDownPayload(outlet)}
            placeholder={"Select outlt"}
          />
          {/* unit */}
        </Form.Item>
      </div>

      <div className="grid grid-cols-2 gap-5">
        <Form.Item
          name="paymentStatus"
          rules={[
            { required: true, message: "Please input the Payment Status!" },
          ]}
        >
          <CustomSelect
            options={dropDownPayload(customer)}
            placeholder={"Select Payment Status"}
          />
          {/* unit */}
        </Form.Item>

        <Form.Item
          name="paymentType"
          rules={[
            { required: true, message: "Please input the Payment Type!" },
          ]}
        >
          <CustomSelect
            options={dropDownPayload(outlet)}
            placeholder={"Select Payment Type"}
          />
          {/* unit */}
        </Form.Item>
      </div>
      <div className="">
        <Form.Item
          name="description"
          label={<span className="text-sm text-lightGrey">Description</span>}
          rules={[
            {
              required: true,
              message: "Please enter a description",
            },
          ]}
        >
          <Input.TextArea
            rows={5}
            className="border border-lightGrey rounded-2xl resize-none py-3 px-5"
            placeholder="Enter your description here"
          />
        </Form.Item>
      </div>
    </>
  );
}

export default Information;
