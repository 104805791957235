import React, { useCallback, useEffect, useState } from "react";
import SearchBar from "../../../component/processSales/searchBar";
import ProductListing from "../../../component/processSales/productListing";
import ProcessSidebar from "../../../component/processSales/processSidebar";
import { ServerError } from "../../../component/shared/notification";
import { getAllProductTypeApi } from "../../../services/product/productType";
import { getAllProductsByTypeId } from "../../../services/product";
import { useDebounce } from "../../../hooks/useDebounce";
import { Form } from "antd";
import { dropDownPayloadForAttribute } from "../../../utils/helper";
import { getAllTaxApi } from "../../../services/product/tax";
import { getAllListOfValueByKey } from "../../../services/globalService";

function ProcessSales() {
  const [form] = Form.useForm();
  const [productType, setProductType] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedProductType, setSelectedProductType] = useState();
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [tempSelectedProducts, setTempSelectedProducts] = useState([]);
  const [combinationModal, setCombinationModal] = useState(false);
  const debouncedValue = useDebounce(searchText || "");
  const [selecectedAttribute, setSelectedAttribute] = useState([]);
  const [tempAttibute, setTempAttibute] = useState([]);
  const [discountType, setDiscountType] = useState([]);

  const getAllProducts = useCallback(async (id) => {
    try {
      const {
        data: { data },
      } = await getAllProductsByTypeId(id, debouncedValue);
      setProducts(data.data);
    } catch {
      ServerError();
    }
  }, []);

  useEffect(() => {
    if (selectedProductType && debouncedValue !== null) {
      getAllProducts(selectedProductType._id, debouncedValue);
    }
  }, [debouncedValue, selectedProductType]); // Trigger the effect on both debouncedValue and selectedProductType changes

  const getProductType = async () => {
    try {
      const {
        data: { data },
      } = await getAllProductTypeApi();
      setProductType(data.data);
    } catch {
      ServerError();
    }
  };

  useEffect(() => {
    getProductType();
  }, []);

  const getAllTax = async () => {
    try {
      const discountResponse = await getAllListOfValueByKey("discount_type");
      setDiscountType(discountResponse.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllTax();
  }, []);

  const onClickProductType = (type) => {
    setTempSelectedProducts([]);
    setSelectedProductType(type); // Set the selected product type and let useEffect handle the API call
  };

  const getTypes = (data) => {
    return data.map((item) => item.type);
  };
  const handleAddSelectedProduct = (newItem) => {
    if (newItem.variants.length > 1) {
      setCombinationModal(true);
      setSelectedAttribute(dropDownPayloadForAttribute(newItem.attributes));
      setTempAttibute(newItem.attributes);
      form.setFieldsValue({
        attribute: getTypes(newItem.attributes),
        name: newItem.name,
        quantity: 1,
      });
      const updatedListing = tempSelectedProducts;
      updatedListing.push(newItem);
      updatedListing.forEach((product) => {
        product.variants.forEach((variant) => {
          variant.discountType = discountType.find(
            (data) => data._id == variant.discountTypeId,
          ).key;
        });
      });
      setTempSelectedProducts(updatedListing);
    } else {
      setSelectedAttribute(dropDownPayloadForAttribute(newItem.attributes));
      setTempAttibute(newItem.attributes);

      const hasMatchingVariantId = () => {
        return selectedProducts.some((product) =>
          product.variants.some(
            (variant) => variant._id == newItem.variants[0]._id,
          ),
        );
      };
      if (hasMatchingVariantId()) {
        const samaProduct = selectedProducts.find((product) =>
          product.variants.some(
            (variant) => variant._id == newItem.variants[0]._id,
          ),
        );
        const sameProductVarientID = samaProduct.variants[0]._id;
        samaProduct.variants[0].quantity =
          Number(samaProduct.variants[0].quantity) + Number(1);
        const updatedProducts = deleteProductByVariantId(
          selectedProducts,
          sameProductVarientID,
        );
        updatedProducts.push(samaProduct);

        updatedProducts.forEach((product) => {
          product.variants.forEach((variant) => {
            variant.discountType = discountType.find(
              (data) => data._id == variant.discountTypeId,
            ).key;
          });
        });

        setSelectedProducts(updatedProducts);
        onClickCloseCombination();
      } else {
        const updatedProduct = selectedProducts;
        newItem.variants[0].quantity = Number(1);
        updatedProduct.push(newItem);
        updatedProduct.forEach((product) => {
          product.variants.forEach((variant) => {
            variant.discountType = discountType.find(
              (data) => data._id == variant.discountTypeId,
            ).key;
          });
        });
        setSelectedProducts(updatedProduct);
      }
    }
  };

  const onClickOpenCombination = () => {
    setCombinationModal(true);
  };

  const onClickCloseCombination = () => {
    setCombinationModal(false);
    form.resetFields();
  };

  const deleteProductByVariantId = (products, variant_id) => {
    return products.filter(
      (product) =>
        !product.variants.some((variant) => variant._id === variant_id),
    );
  };

  const onFinishListingModal = (values) => {
    const outputObject = values?.attribute.reduce((acc, key) => {
      if (key in values) {
        acc[key] = values[key];
      }
      return acc;
    }, {});
    const combinationToMatch = Object.values(outputObject);

    const products = tempSelectedProducts.find(
      (product) => product.name == values.name,
    );

    const matchingProduct = products.variants.find(
      (product) =>
        JSON.stringify(product.combination) ===
        JSON.stringify(combinationToMatch),
    );
    const hasMatchingVariantId = () => {
      return selectedProducts.some((product) =>
        product.variants.some((variant) => variant._id == matchingProduct._id),
      );
    };
    if (hasMatchingVariantId()) {
      const samaProduct = selectedProducts.find((product) =>
        product.variants.some((variant) => variant._id == matchingProduct._id),
      );
      const sameProductVarientID = samaProduct.variants[0]._id;
      samaProduct.variants[0].quantity =
        Number(samaProduct.variants[0].quantity) + 1;
      const updatedProducts = deleteProductByVariantId(
        selectedProducts,
        sameProductVarientID,
      );
      updatedProducts.push(samaProduct);
      setSelectedProducts(updatedProducts);
      onClickCloseCombination();
    } else {
      matchingProduct.quantity = Number(values.quantity);
      const payload = {
        ...products,
        variants: [matchingProduct],
      };
      const updatedItem = selectedProducts;
      updatedItem.push(payload);
      setSelectedProducts(updatedItem);
      onClickCloseCombination();
    }
  };

  return (
    <main className="mt-6">
      <div className="grid grid-cols-12 gap-10 h-full">
        <div className="col-span-8">
          <div className="flex items-center justify-start mb-5">
            <div className="text-2xl font-medium">Store</div>
            <div className="mx-5">
              <svg
                width={17}
                height={16}
                viewBox="0 0 17 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 14.9999L8 7.99996L1 0.999979"
                  stroke="#BABABA"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9 15L16 7.99999L9 1.00001"
                  stroke="black"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="text-2xl font-bold">Cash Register</div>
          </div>

          <SearchBar setSearchText={setSearchText} />
          <div className="block border-t border-lightGrey mt-7 pt-6">
            <ul className="flex items-center justify-start gap-2.5 mb-6">
              {productType.map((type, index) => {
                return (
                  <li
                    onClick={() => onClickProductType(type)}
                    key={index + 1}
                    className={`${type?._id == selectedProductType?._id ? "active" : ""} border border-veryLightGrey bg-white rounded-full text-sm text-lightGrey leading-none py-2.5 px-4 [&.active]:bg-brightUFOGreen [&.active]:text-ufoGreen [&.active]:border-ufoGreen [&.active]:font-bold cursor-pointer`}
                  >
                    {type.name}
                  </li>
                );
              })}
            </ul>
            <ProductListing
              products={products}
              handleAddSelectedProduct={handleAddSelectedProduct}
              onClickCloseCombination={onClickCloseCombination}
              combinationModal={combinationModal}
              form={form}
              onFinishListingModal={onFinishListingModal}
              selecectedAttribute={selecectedAttribute}
              tempAttibute={tempAttibute}
            />
          </div>
        </div>
        <div className="col-span-4 -mr-5">
          <ProcessSidebar
            selectedProductState={selectedProducts}
            setSelectedProductState={setSelectedProducts}
          />
        </div>
      </div>
    </main>
  );
}

export default ProcessSales;
