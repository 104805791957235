import Api from "../../network/client";

export const getRegisterDetailsApi = async (id) => {
  return Api.get(`cash-register/current?outletId=${id}`);
};
export const closeRegister = async (id, payload, registerID) => {
  return Api.put(`cash-register/${id}/close/${registerID}`, payload);
};
export const openRegister = async (payload, id) => {
  return Api.put(`cash-register/${id}/open`, payload);
};
export const cashInnOutApi = async (
  registerID,
  registerDetailsId,
  type,
  payload,
) => {
  return Api.post(
    `cash-register/${registerID}/active-register/${registerDetailsId}/transaction/${type}`,
    payload,
  );
};
