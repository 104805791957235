export const Menu = [
  {
    id: 1,
    to: "./dashboard",
    label: "Dashboard",
    permission: ["Poxfy User", "Tenant User", "Super User"],
    icon: (
      <span className="flex items-center justify-center w-10 mr-2 rounded-full aspect-square bg-veryLightGrey transition ease-in-out group-hover:bg-white group-hover:border-2 group-hover:border-redOrange">
        <svg
          width={21}
          height={22}
          viewBox="0 0 21 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="group-hover:stroke-redOrange"
            d="M7.65722 19.7714V16.7047C7.6572 15.9246 8.29312 15.2908 9.08101 15.2856H11.9671C12.7587 15.2856 13.4005 15.9209 13.4005 16.7047V19.7809C13.4003 20.4432 13.9343 20.9845 14.603 21H16.5271C18.4451 21 20 19.4607 20 17.5618V8.83784C19.9898 8.09083 19.6355 7.38935 19.038 6.93303L12.4577 1.6853C11.3049 0.771566 9.6662 0.771566 8.51342 1.6853L1.96203 6.94256C1.36226 7.39702 1.00739 8.09967 1 8.84736V17.5618C1 19.4607 2.55488 21 4.47291 21H6.39696C7.08235 21 7.63797 20.4499 7.63797 19.7714"
            stroke="#BABABA"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    ),
  },
  {
    id: 11,
    to: "./dashboard",
    label: "Point of Sales",
    // permission: ["Tenant User", "Super User"],
    permission: ["Poxfy User", "Tenant User", "Super User"],
    icon: (
      <span class="flex items-center justify-center w-10 mr-2 rounded-full aspect-square bg-veryLightGrey transition ease-in-out group-hover:bg-white group-[.menu-item-has-children.active]:bg-white">
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
            d="M20.8164 1.4234C20.808 1.2692 20.743 1.12316 20.6332 1.01225C20.5234 0.901343 20.3764 0.833058 20.2192 0.820007L11.7829 0.00323752C11.6868 -0.00629188 11.5897 0.00527923 11.4988 0.0371079C11.4078 0.0689366 11.3253 0.120221 11.2572 0.187226L1.31292 9.92202C-0.178687 11.3821 -0.178687 12.2727 1.31292 13.7324L7.62327 19.9101C8.2919 20.5649 8.87862 21 9.56977 21C10.2609 21 10.8476 20.5637 11.515 19.9098L21.4608 10.1744C21.5292 10.1076 21.5815 10.0268 21.614 9.93777C21.6465 9.84878 21.6584 9.75382 21.6488 9.65977L20.8164 1.4234ZM10.5925 19.0065C10.2789 19.3134 9.83148 19.722 9.56977 19.722C9.30773 19.722 8.86002 19.3134 8.5461 19.0065L2.23575 12.8288C1.26038 11.8743 1.26038 11.7801 2.23575 10.8257L11.9627 1.30426L19.5669 2.04085L20.32 9.48473L10.5925 19.0065Z"
            fill="#BABABA"
          />
          <path
            class="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
            d="M7.02121 7.78348L4.05497 10.6867C3.30607 11.4198 3.10049 11.9168 3.95806 12.7563C3.98829 12.7861 4.02424 12.8097 4.06383 12.8258C4.10343 12.8419 4.14588 12.8502 4.18877 12.8502C4.23165 12.8502 4.2741 12.8419 4.3137 12.8258C4.35329 12.8097 4.38924 12.7861 4.41947 12.7563C4.4498 12.7267 4.47387 12.6915 4.49028 12.6527C4.5067 12.614 4.51515 12.5724 4.51515 12.5305C4.51515 12.4885 4.5067 12.447 4.49028 12.4082C4.47387 12.3695 4.4498 12.3343 4.41947 12.3046C3.90813 11.8041 3.91433 11.7281 4.51639 11.1384L7.48263 8.23515C7.51293 8.20549 7.53696 8.17029 7.55336 8.13154C7.56975 8.09279 7.57819 8.05126 7.57819 8.00932C7.57819 7.96738 7.56975 7.92585 7.55336 7.8871C7.53696 7.84835 7.51293 7.81314 7.48263 7.78348C7.45233 7.75383 7.41637 7.7303 7.37678 7.71425C7.3372 7.6982 7.29477 7.68994 7.25192 7.68994C7.20908 7.68994 7.16665 7.6982 7.12706 7.71425C7.08748 7.7303 7.05151 7.75383 7.02121 7.78348Z"
            fill="#BABABA"
          />
          <path
            class="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
            d="M7.94964 6.87408L7.68435 7.13409C7.65409 7.16379 7.63011 7.19903 7.61377 7.2378C7.59743 7.27657 7.58905 7.31811 7.58911 7.36005C7.58917 7.40199 7.59767 7.44351 7.61412 7.48224C7.63058 7.52097 7.65466 7.55614 7.685 7.58575C7.71534 7.61537 7.75134 7.63884 7.79095 7.65484C7.83056 7.67084 7.873 7.67904 7.91584 7.67898C7.95869 7.67892 8.0011 7.6706 8.04067 7.65449C8.08023 7.63839 8.11616 7.61481 8.14641 7.58512L8.41171 7.3251C8.44197 7.29541 8.46595 7.26016 8.48229 7.22139C8.49863 7.18262 8.50701 7.14108 8.50695 7.09914C8.50689 7.0572 8.49839 7.01568 8.48194 6.97695C8.46548 6.93823 8.4414 6.90305 8.41106 6.87344C8.38072 6.84382 8.34472 6.82035 8.30511 6.80435C8.2655 6.78836 8.22306 6.78015 8.18022 6.78021C8.13737 6.78027 8.09496 6.78859 8.05539 6.8047C8.01583 6.8208 7.9799 6.84438 7.94964 6.87408Z"
            fill="#BABABA"
          />
          <path
            class="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
            d="M15.6886 3.10901C14.197 3.10901 12.9824 4.29727 12.9824 5.75768C12.9824 7.21809 14.197 8.40635 15.6886 8.40635C17.1808 8.40635 18.3941 7.21809 18.3941 5.75768C18.3941 4.29727 17.1805 3.10901 15.6886 3.10901ZM15.6886 7.12865C15.3172 7.12823 14.9612 6.98366 14.6986 6.72666C14.436 6.46965 14.2882 6.12118 14.2877 5.75768C14.2882 5.39418 14.436 5.04571 14.6986 4.7887C14.9612 4.5317 15.3172 4.38713 15.6886 4.38671C16.0599 4.38705 16.4159 4.53161 16.6784 4.78865C16.941 5.04569 17.0886 5.39421 17.0888 5.75768C17.0885 6.12113 16.9408 6.46959 16.6783 6.72662C16.4158 6.98364 16.0599 7.12823 15.6886 7.12865Z"
            fill="#BABABA"
          />
        </svg>
      </span>
    ),
    children: [
      {
        id: 1,
        to: "./pos/process-sales",
        label: "Process Sales",
      },
      {
        id: 2,
        to: "./pos/sale-history",
        label: "Sale History",
      },
      {
        id: 3,
        to: "./pos/cash-register",
        label: "Cash Registers",
      },
      {
        id: 4,
        to: "./purchase",
        label: "Purchase",
      },
    ],
  },
  {
    id: 2,
    to: "./settings",
    label: "Landing Page",
    permission: ["Poxfy User", "Super User"],

    icon: (
      <span className="flex items-center justify-center w-10 mr-2 rounded-full aspect-square bg-veryLightGrey transition ease-in-out group-hover:bg-white group-hover:border-2 group-hover:border-redOrange">
        <svg
          width={21}
          height={20}
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="group-hover:fill-redOrange"
            d="M19.6703 5.25562V17.2596C19.6703 17.8142 19.6573 18.3688 19.6703 18.9234V18.9472L20.3203 18.2973H1.45317C1.19753 18.2973 0.941899 18.293 0.686264 18.2973H0.653769L1.30369 18.9472V6.94323C1.30369 6.38864 1.31668 5.83404 1.30369 5.27945V5.25562L0.653769 5.90553H19.5209C19.7765 5.90553 20.0321 5.9077 20.2878 5.90553H20.3203C20.6604 5.90553 20.9853 5.60657 20.9702 5.25562C20.955 4.90249 20.6842 4.6057 20.3203 4.6057H1.45317C1.19753 4.6057 0.941899 4.60353 0.686264 4.6057H0.653769C0.302813 4.6057 0.00385136 4.90249 0.00385136 5.25562V17.2596C0.00385136 17.8142 -0.0048142 18.3688 0.00385136 18.9234V18.9472C0.00385136 19.2982 0.300647 19.5971 0.653769 19.5971H19.5209C19.7765 19.5971 20.0321 19.5993 20.2878 19.5971H20.3203C20.6712 19.5971 20.9702 19.3003 20.9702 18.9472V6.94323C20.9702 6.38864 20.9788 5.83404 20.9702 5.27945V5.25562C20.9702 4.91549 20.6712 4.59053 20.3203 4.6057C19.9671 4.62086 19.6703 4.89166 19.6703 5.25562Z"
            fill="#BABABA"
          />
          <path
            className="group-hover:fill-redOrange"
            d="M20.3184 4.60795H1.45133C1.19569 4.60795 0.940058 4.60362 0.684424 4.60795H0.651928C0.805742 4.97841 0.957389 5.34669 1.1112 5.71715C1.59864 5.19721 2.08824 4.67945 2.57568 4.15951C3.35342 3.33195 4.13115 2.50656 4.90889 1.679L5.44182 1.11357C5.288 1.17639 5.13636 1.24138 4.98254 1.30421H14.6338C15.0779 1.30421 15.5242 1.31721 15.9705 1.30421H15.9878C15.834 1.24138 15.6823 1.17639 15.5285 1.11357C16.016 1.6335 16.5056 2.15127 16.993 2.6712C17.7707 3.49876 18.5485 4.32416 19.3262 5.15172L19.8591 5.71715C20.0931 5.96628 20.5459 5.97062 20.7777 5.71715C21.0225 5.45068 21.0268 5.0629 20.7777 4.7986C20.3358 4.32849 19.8938 3.85838 19.4519 3.39044C18.7045 2.59538 17.9549 1.80031 17.2075 1.00525C16.9822 0.764778 16.7569 0.526475 16.5294 0.286006C16.3799 0.127859 16.2326 0.0130403 15.9835 0.00437477H5.314C5.15369 0.00437477 4.96521 -0.0151227 4.80707 0.0282051C4.54277 0.101862 4.33696 0.389992 4.15715 0.580635L2.06441 2.80335L0.283641 4.69461L0.186154 4.7986C0.004177 4.99141 -0.0521492 5.26437 0.0518376 5.50917C0.151492 5.74531 0.385462 5.90779 0.645429 5.90779H19.5125C19.7682 5.90779 20.0238 5.90996 20.2794 5.90779H20.3119C20.652 5.90779 20.977 5.60883 20.9618 5.25787C20.9532 4.90475 20.6824 4.60795 20.3184 4.60795Z"
            fill="#BABABA"
          />
          <path
            className="group-hover:fill-redOrange"
            d="M11.9632 5.25556V11.3345C12.2882 11.1481 12.6153 10.9597 12.9402 10.7734C12.208 10.3899 11.4779 10.0065 10.7457 9.623C10.6504 9.57318 10.5572 9.52118 10.4619 9.47352C10.2258 9.35437 10.0005 9.37387 9.77515 9.48869C9.72532 9.51252 9.67766 9.53852 9.63 9.56451C9.20539 9.78765 8.78077 10.0108 8.35399 10.2318C8.00954 10.4116 7.66725 10.5914 7.32279 10.7712C7.64775 10.9575 7.97488 11.146 8.29983 11.3323V5.25339L7.64992 5.90331H12.6131C12.9532 5.90331 13.2782 5.60435 13.263 5.25339C13.2479 4.90027 12.9771 4.60348 12.6131 4.60348H7.64992C7.29896 4.60348 7 4.90027 7 5.25339V11.3323C7 11.8414 7.5481 12.1187 7.97704 11.8934C8.70062 11.5143 9.42203 11.1351 10.1456 10.756L10.4576 10.5935H9.80114C10.5247 10.9727 11.2461 11.3518 11.9697 11.7309L12.2817 11.8934C12.7128 12.1187 13.2587 11.8414 13.2587 11.3323V5.25339C13.2587 4.91327 12.9597 4.58831 12.6088 4.60348C12.26 4.62081 11.9632 4.89161 11.9632 5.25556Z"
            fill="#BABABA"
          />
        </svg>
      </span>
    ),
  },
  {
    id: 3,
    to: "./page",
    label: "Pages",
    permission: ["Poxfy User", "Super User"],
    icon: (
      <span className="flex items-center justify-center w-10 mr-2 rounded-full aspect-square bg-veryLightGrey transition ease-in-out group-hover:bg-white group-hover:border-2 group-hover:border-redOrange">
        <svg
          width={21}
          height={20}
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="group-hover:fill-redOrange"
            d="M19.6703 5.25562V17.2596C19.6703 17.8142 19.6573 18.3688 19.6703 18.9234V18.9472L20.3203 18.2973H1.45317C1.19753 18.2973 0.941899 18.293 0.686264 18.2973H0.653769L1.30369 18.9472V6.94323C1.30369 6.38864 1.31668 5.83404 1.30369 5.27945V5.25562L0.653769 5.90553H19.5209C19.7765 5.90553 20.0321 5.9077 20.2878 5.90553H20.3203C20.6604 5.90553 20.9853 5.60657 20.9702 5.25562C20.955 4.90249 20.6842 4.6057 20.3203 4.6057H1.45317C1.19753 4.6057 0.941899 4.60353 0.686264 4.6057H0.653769C0.302813 4.6057 0.00385136 4.90249 0.00385136 5.25562V17.2596C0.00385136 17.8142 -0.0048142 18.3688 0.00385136 18.9234V18.9472C0.00385136 19.2982 0.300647 19.5971 0.653769 19.5971H19.5209C19.7765 19.5971 20.0321 19.5993 20.2878 19.5971H20.3203C20.6712 19.5971 20.9702 19.3003 20.9702 18.9472V6.94323C20.9702 6.38864 20.9788 5.83404 20.9702 5.27945V5.25562C20.9702 4.91549 20.6712 4.59053 20.3203 4.6057C19.9671 4.62086 19.6703 4.89166 19.6703 5.25562Z"
            fill="#BABABA"
          />
          <path
            className="group-hover:fill-redOrange"
            d="M20.3184 4.60795H1.45133C1.19569 4.60795 0.940058 4.60362 0.684424 4.60795H0.651928C0.805742 4.97841 0.957389 5.34669 1.1112 5.71715C1.59864 5.19721 2.08824 4.67945 2.57568 4.15951C3.35342 3.33195 4.13115 2.50656 4.90889 1.679L5.44182 1.11357C5.288 1.17639 5.13636 1.24138 4.98254 1.30421H14.6338C15.0779 1.30421 15.5242 1.31721 15.9705 1.30421H15.9878C15.834 1.24138 15.6823 1.17639 15.5285 1.11357C16.016 1.6335 16.5056 2.15127 16.993 2.6712C17.7707 3.49876 18.5485 4.32416 19.3262 5.15172L19.8591 5.71715C20.0931 5.96628 20.5459 5.97062 20.7777 5.71715C21.0225 5.45068 21.0268 5.0629 20.7777 4.7986C20.3358 4.32849 19.8938 3.85838 19.4519 3.39044C18.7045 2.59538 17.9549 1.80031 17.2075 1.00525C16.9822 0.764778 16.7569 0.526475 16.5294 0.286006C16.3799 0.127859 16.2326 0.0130403 15.9835 0.00437477H5.314C5.15369 0.00437477 4.96521 -0.0151227 4.80707 0.0282051C4.54277 0.101862 4.33696 0.389992 4.15715 0.580635L2.06441 2.80335L0.283641 4.69461L0.186154 4.7986C0.004177 4.99141 -0.0521492 5.26437 0.0518376 5.50917C0.151492 5.74531 0.385462 5.90779 0.645429 5.90779H19.5125C19.7682 5.90779 20.0238 5.90996 20.2794 5.90779H20.3119C20.652 5.90779 20.977 5.60883 20.9618 5.25787C20.9532 4.90475 20.6824 4.60795 20.3184 4.60795Z"
            fill="#BABABA"
          />
          <path
            className="group-hover:fill-redOrange"
            d="M11.9632 5.25556V11.3345C12.2882 11.1481 12.6153 10.9597 12.9402 10.7734C12.208 10.3899 11.4779 10.0065 10.7457 9.623C10.6504 9.57318 10.5572 9.52118 10.4619 9.47352C10.2258 9.35437 10.0005 9.37387 9.77515 9.48869C9.72532 9.51252 9.67766 9.53852 9.63 9.56451C9.20539 9.78765 8.78077 10.0108 8.35399 10.2318C8.00954 10.4116 7.66725 10.5914 7.32279 10.7712C7.64775 10.9575 7.97488 11.146 8.29983 11.3323V5.25339L7.64992 5.90331H12.6131C12.9532 5.90331 13.2782 5.60435 13.263 5.25339C13.2479 4.90027 12.9771 4.60348 12.6131 4.60348H7.64992C7.29896 4.60348 7 4.90027 7 5.25339V11.3323C7 11.8414 7.5481 12.1187 7.97704 11.8934C8.70062 11.5143 9.42203 11.1351 10.1456 10.756L10.4576 10.5935H9.80114C10.5247 10.9727 11.2461 11.3518 11.9697 11.7309L12.2817 11.8934C12.7128 12.1187 13.2587 11.8414 13.2587 11.3323V5.25339C13.2587 4.91327 12.9597 4.58831 12.6088 4.60348C12.26 4.62081 11.9632 4.89161 11.9632 5.25556Z"
            fill="#BABABA"
          />
        </svg>
      </span>
    ),
  },
  {
    id: 4,
    to: "./products",
    label: "Products",
    permission: ["Tenant User", "Super User"],
    icon: (
      <span className="flex items-center justify-center w-10 mr-2 rounded-full aspect-square bg-veryLightGrey transition ease-in-out group-hover:bg-white group-hover:border-2 group-hover:border-redOrange">
        <svg
          width={21}
          height={20}
          viewBox="0 0 21 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className="group-hover:fill-redOrange"
            d="M19.6703 5.25562V17.2596C19.6703 17.8142 19.6573 18.3688 19.6703 18.9234V18.9472L20.3203 18.2973H1.45317C1.19753 18.2973 0.941899 18.293 0.686264 18.2973H0.653769L1.30369 18.9472V6.94323C1.30369 6.38864 1.31668 5.83404 1.30369 5.27945V5.25562L0.653769 5.90553H19.5209C19.7765 5.90553 20.0321 5.9077 20.2878 5.90553H20.3203C20.6604 5.90553 20.9853 5.60657 20.9702 5.25562C20.955 4.90249 20.6842 4.6057 20.3203 4.6057H1.45317C1.19753 4.6057 0.941899 4.60353 0.686264 4.6057H0.653769C0.302813 4.6057 0.00385136 4.90249 0.00385136 5.25562V17.2596C0.00385136 17.8142 -0.0048142 18.3688 0.00385136 18.9234V18.9472C0.00385136 19.2982 0.300647 19.5971 0.653769 19.5971H19.5209C19.7765 19.5971 20.0321 19.5993 20.2878 19.5971H20.3203C20.6712 19.5971 20.9702 19.3003 20.9702 18.9472V6.94323C20.9702 6.38864 20.9788 5.83404 20.9702 5.27945V5.25562C20.9702 4.91549 20.6712 4.59053 20.3203 4.6057C19.9671 4.62086 19.6703 4.89166 19.6703 5.25562Z"
            fill="#BABABA"
          />
          <path
            className="group-hover:fill-redOrange"
            d="M20.3184 4.60795H1.45133C1.19569 4.60795 0.940058 4.60362 0.684424 4.60795H0.651928C0.805742 4.97841 0.957389 5.34669 1.1112 5.71715C1.59864 5.19721 2.08824 4.67945 2.57568 4.15951C3.35342 3.33195 4.13115 2.50656 4.90889 1.679L5.44182 1.11357C5.288 1.17639 5.13636 1.24138 4.98254 1.30421H14.6338C15.0779 1.30421 15.5242 1.31721 15.9705 1.30421H15.9878C15.834 1.24138 15.6823 1.17639 15.5285 1.11357C16.016 1.6335 16.5056 2.15127 16.993 2.6712C17.7707 3.49876 18.5485 4.32416 19.3262 5.15172L19.8591 5.71715C20.0931 5.96628 20.5459 5.97062 20.7777 5.71715C21.0225 5.45068 21.0268 5.0629 20.7777 4.7986C20.3358 4.32849 19.8938 3.85838 19.4519 3.39044C18.7045 2.59538 17.9549 1.80031 17.2075 1.00525C16.9822 0.764778 16.7569 0.526475 16.5294 0.286006C16.3799 0.127859 16.2326 0.0130403 15.9835 0.00437477H5.314C5.15369 0.00437477 4.96521 -0.0151227 4.80707 0.0282051C4.54277 0.101862 4.33696 0.389992 4.15715 0.580635L2.06441 2.80335L0.283641 4.69461L0.186154 4.7986C0.004177 4.99141 -0.0521492 5.26437 0.0518376 5.50917C0.151492 5.74531 0.385462 5.90779 0.645429 5.90779H19.5125C19.7682 5.90779 20.0238 5.90996 20.2794 5.90779H20.3119C20.652 5.90779 20.977 5.60883 20.9618 5.25787C20.9532 4.90475 20.6824 4.60795 20.3184 4.60795Z"
            fill="#BABABA"
          />
          <path
            className="group-hover:fill-redOrange"
            d="M11.9632 5.25556V11.3345C12.2882 11.1481 12.6153 10.9597 12.9402 10.7734C12.208 10.3899 11.4779 10.0065 10.7457 9.623C10.6504 9.57318 10.5572 9.52118 10.4619 9.47352C10.2258 9.35437 10.0005 9.37387 9.77515 9.48869C9.72532 9.51252 9.67766 9.53852 9.63 9.56451C9.20539 9.78765 8.78077 10.0108 8.35399 10.2318C8.00954 10.4116 7.66725 10.5914 7.32279 10.7712C7.64775 10.9575 7.97488 11.146 8.29983 11.3323V5.25339L7.64992 5.90331H12.6131C12.9532 5.90331 13.2782 5.60435 13.263 5.25339C13.2479 4.90027 12.9771 4.60348 12.6131 4.60348H7.64992C7.29896 4.60348 7 4.90027 7 5.25339V11.3323C7 11.8414 7.5481 12.1187 7.97704 11.8934C8.70062 11.5143 9.42203 11.1351 10.1456 10.756L10.4576 10.5935H9.80114C10.5247 10.9727 11.2461 11.3518 11.9697 11.7309L12.2817 11.8934C12.7128 12.1187 13.2587 11.8414 13.2587 11.3323V5.25339C13.2587 4.91327 12.9597 4.58831 12.6088 4.60348C12.26 4.62081 11.9632 4.89161 11.9632 5.25556Z"
            fill="#BABABA"
          />
        </svg>
      </span>
    ),
    children: [
      {
        id: 1,
        to: "./products",
        label: "Products",
      },
      {
        id: 2,
        to: "./products/product-types",
        label: "Product Types",
      },
      {
        id: 3,
        to: "./products/brands",
        label: "Brands",
      },
      {
        id: 4,
        to: "./products/tags",
        label: "Tags",
      },
      {
        id: 5,
        to: "./products/seasons",
        label: "Seasons",
      },
      {
        id: 6,
        to: "./products/tax",
        label: "Tax",
      },
      {
        id: 7,
        to: "./products/unit",
        label: "Unit",
      },
    ],
    //   icon: (
    //     <>
    //       <span className="flex items-center justify-center w-10 mr-2 rounded-full aspect-square bg-veryLightGrey transition ease-in-out group-hover:bg-white group-[.menu-item-has-children.active]:bg-white">
    //         <svg
    //           width="21"
    //           height="20"
    //           viewBox="0 0 21 20"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path
    //             className="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
    //             d="M19.6703 5.25562V17.2596C19.6703 17.8142 19.6573 18.3688 19.6703 18.9234V18.9472L20.3203 18.2973H1.45317C1.19753 18.2973 0.941899 18.293 0.686264 18.2973H0.653769L1.30369 18.9472V6.94323C1.30369 6.38864 1.31668 5.83404 1.30369 5.27945V5.25562L0.653769 5.90553H19.5209C19.7765 5.90553 20.0321 5.9077 20.2878 5.90553H20.3203C20.6604 5.90553 20.9853 5.60657 20.9702 5.25562C20.955 4.90249 20.6842 4.6057 20.3203 4.6057H1.45317C1.19753 4.6057 0.941899 4.60353 0.686264 4.6057H0.653769C0.302813 4.6057 0.00385136 4.90249 0.00385136 5.25562V17.2596C0.00385136 17.8142 -0.0048142 18.3688 0.00385136 18.9234V18.9472C0.00385136 19.2982 0.300647 19.5971 0.653769 19.5971H19.5209C19.7765 19.5971 20.0321 19.5993 20.2878 19.5971H20.3203C20.6712 19.5971 20.9702 19.3003 20.9702 18.9472V6.94323C20.9702 6.38864 20.9788 5.83404 20.9702 5.27945V5.25562C20.9702 4.91549 20.6712 4.59053 20.3203 4.6057C19.9671 4.62086 19.6703 4.89166 19.6703 5.25562Z"
    //             fill="#BABABA"
    //           />
    //           <path
    //             className="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
    //             d="M20.3184 4.60795H1.45133C1.19569 4.60795 0.940058 4.60362 0.684424 4.60795H0.651928C0.805742 4.97841 0.957389 5.34669 1.1112 5.71715C1.59864 5.19721 2.08824 4.67945 2.57568 4.15951C3.35342 3.33195 4.13115 2.50656 4.90889 1.679L5.44182 1.11357C5.288 1.17639 5.13636 1.24138 4.98254 1.30421H14.6338C15.0779 1.30421 15.5242 1.31721 15.9705 1.30421H15.9878C15.834 1.24138 15.6823 1.17639 15.5285 1.11357C16.016 1.6335 16.5056 2.15127 16.993 2.6712C17.7707 3.49876 18.5485 4.32416 19.3262 5.15172L19.8591 5.71715C20.0931 5.96628 20.5459 5.97062 20.7777 5.71715C21.0225 5.45068 21.0268 5.0629 20.7777 4.7986C20.3358 4.32849 19.8938 3.85838 19.4519 3.39044C18.7045 2.59538 17.9549 1.80031 17.2075 1.00525C16.9822 0.764778 16.7569 0.526475 16.5294 0.286006C16.3799 0.127859 16.2326 0.0130403 15.9835 0.00437477H5.314C5.15369 0.00437477 4.96521 -0.0151227 4.80707 0.0282051C4.54277 0.101862 4.33696 0.389992 4.15715 0.580635L2.06441 2.80335L0.283641 4.69461L0.186154 4.7986C0.004177 4.99141 -0.0521492 5.26437 0.0518376 5.50917C0.151492 5.74531 0.385462 5.90779 0.645429 5.90779H19.5125C19.7682 5.90779 20.0238 5.90996 20.2794 5.90779H20.3119C20.652 5.90779 20.977 5.60883 20.9618 5.25787C20.9532 4.90475 20.6824 4.60795 20.3184 4.60795Z"
    //             fill="#BABABA"
    //           />
    //           <path
    //             classNamelass="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
    //             d="M11.9632 5.25556V11.3345C12.2882 11.1481 12.6153 10.9597 12.9402 10.7734C12.208 10.3899 11.4779 10.0065 10.7457 9.623C10.6504 9.57318 10.5572 9.52118 10.4619 9.47352C10.2258 9.35437 10.0005 9.37387 9.77515 9.48869C9.72532 9.51252 9.67766 9.53852 9.63 9.56451C9.20539 9.78765 8.78077 10.0108 8.35399 10.2318C8.00954 10.4116 7.66725 10.5914 7.32279 10.7712C7.64775 10.9575 7.97488 11.146 8.29983 11.3323V5.25339L7.64992 5.90331H12.6131C12.9532 5.90331 13.2782 5.60435 13.263 5.25339C13.2479 4.90027 12.9771 4.60348 12.6131 4.60348H7.64992C7.29896 4.60348 7 4.90027 7 5.25339V11.3323C7 11.8414 7.5481 12.1187 7.97704 11.8934C8.70062 11.5143 9.42203 11.1351 10.1456 10.756L10.4576 10.5935H9.80114C10.5247 10.9727 11.2461 11.3518 11.9697 11.7309L12.2817 11.8934C12.7128 12.1187 13.2587 11.8414 13.2587 11.3323V5.25339C13.2587 4.91327 12.9597 4.58831 12.6088 4.60348C12.26 4.62081 11.9632 4.89161 11.9632 5.25556Z"
    //             fill="#BABABA"
    //           />
    //         </svg>
    //       </span>
    //       <span className="text-sm text-lightGrey transition ease-in-out group-hover:text-white group-[.menu-item-has-children.active]:text-white"></span>
    //       <span className="absolute top-5 right-4 transition ease-in-out group-[.menu-item-has-children.active]:rotate-180">
    //         <svg
    //           width="12"
    //           height="7"
    //           viewBox="0 0 12 7"
    //           fill="none"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path
    //             className="group-hover:stroke-white group-[.menu-item-has-children.active]:stroke-white"
    //             d="M1 1L6 6L11 1"
    //             stroke="black"
    //             stroke-width="1.5"
    //             stroke-linecap="round"
    //             stroke-linejoin="round"
    //           />
    //         </svg>
    //       </span>
    //     </>
    //   ),
    // },
    // ],
  },
  {
    id: 5,
    to: "./customer",
    label: "Cutomer",
    permission: ["Tenant User", "Super User"],

    children: [
      {
        id: 1,
        to: "./customer",
        label: "Customer",
      },
      {
        id: 2,
        to: "./customer/tag",
        label: "Tag",
      },
      {
        id: 3,
        to: "./customer/tier",
        label: "Tier",
      },
    ],
    icon: (
      <>
        <span className="flex items-center justify-center w-10 mr-2 rounded-full aspect-square bg-veryLightGrey transition ease-in-out group-hover:bg-white group-[.menu-item-has-children.active]:bg-white">
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
              d="M19.6703 5.25562V17.2596C19.6703 17.8142 19.6573 18.3688 19.6703 18.9234V18.9472L20.3203 18.2973H1.45317C1.19753 18.2973 0.941899 18.293 0.686264 18.2973H0.653769L1.30369 18.9472V6.94323C1.30369 6.38864 1.31668 5.83404 1.30369 5.27945V5.25562L0.653769 5.90553H19.5209C19.7765 5.90553 20.0321 5.9077 20.2878 5.90553H20.3203C20.6604 5.90553 20.9853 5.60657 20.9702 5.25562C20.955 4.90249 20.6842 4.6057 20.3203 4.6057H1.45317C1.19753 4.6057 0.941899 4.60353 0.686264 4.6057H0.653769C0.302813 4.6057 0.00385136 4.90249 0.00385136 5.25562V17.2596C0.00385136 17.8142 -0.0048142 18.3688 0.00385136 18.9234V18.9472C0.00385136 19.2982 0.300647 19.5971 0.653769 19.5971H19.5209C19.7765 19.5971 20.0321 19.5993 20.2878 19.5971H20.3203C20.6712 19.5971 20.9702 19.3003 20.9702 18.9472V6.94323C20.9702 6.38864 20.9788 5.83404 20.9702 5.27945V5.25562C20.9702 4.91549 20.6712 4.59053 20.3203 4.6057C19.9671 4.62086 19.6703 4.89166 19.6703 5.25562Z"
              fill="#BABABA"
            />
            <path
              className="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
              d="M20.3184 4.60795H1.45133C1.19569 4.60795 0.940058 4.60362 0.684424 4.60795H0.651928C0.805742 4.97841 0.957389 5.34669 1.1112 5.71715C1.59864 5.19721 2.08824 4.67945 2.57568 4.15951C3.35342 3.33195 4.13115 2.50656 4.90889 1.679L5.44182 1.11357C5.288 1.17639 5.13636 1.24138 4.98254 1.30421H14.6338C15.0779 1.30421 15.5242 1.31721 15.9705 1.30421H15.9878C15.834 1.24138 15.6823 1.17639 15.5285 1.11357C16.016 1.6335 16.5056 2.15127 16.993 2.6712C17.7707 3.49876 18.5485 4.32416 19.3262 5.15172L19.8591 5.71715C20.0931 5.96628 20.5459 5.97062 20.7777 5.71715C21.0225 5.45068 21.0268 5.0629 20.7777 4.7986C20.3358 4.32849 19.8938 3.85838 19.4519 3.39044C18.7045 2.59538 17.9549 1.80031 17.2075 1.00525C16.9822 0.764778 16.7569 0.526475 16.5294 0.286006C16.3799 0.127859 16.2326 0.0130403 15.9835 0.00437477H5.314C5.15369 0.00437477 4.96521 -0.0151227 4.80707 0.0282051C4.54277 0.101862 4.33696 0.389992 4.15715 0.580635L2.06441 2.80335L0.283641 4.69461L0.186154 4.7986C0.004177 4.99141 -0.0521492 5.26437 0.0518376 5.50917C0.151492 5.74531 0.385462 5.90779 0.645429 5.90779H19.5125C19.7682 5.90779 20.0238 5.90996 20.2794 5.90779H20.3119C20.652 5.90779 20.977 5.60883 20.9618 5.25787C20.9532 4.90475 20.6824 4.60795 20.3184 4.60795Z"
              fill="#BABABA"
            />
            <path
              className="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
              d="M11.9632 5.25556V11.3345C12.2882 11.1481 12.6153 10.9597 12.9402 10.7734C12.208 10.3899 11.4779 10.0065 10.7457 9.623C10.6504 9.57318 10.5572 9.52118 10.4619 9.47352C10.2258 9.35437 10.0005 9.37387 9.77515 9.48869C9.72532 9.51252 9.67766 9.53852 9.63 9.56451C9.20539 9.78765 8.78077 10.0108 8.35399 10.2318C8.00954 10.4116 7.66725 10.5914 7.32279 10.7712C7.64775 10.9575 7.97488 11.146 8.29983 11.3323V5.25339L7.64992 5.90331H12.6131C12.9532 5.90331 13.2782 5.60435 13.263 5.25339C13.2479 4.90027 12.9771 4.60348 12.6131 4.60348H7.64992C7.29896 4.60348 7 4.90027 7 5.25339V11.3323C7 11.8414 7.5481 12.1187 7.97704 11.8934C8.70062 11.5143 9.42203 11.1351 10.1456 10.756L10.4576 10.5935H9.80114C10.5247 10.9727 11.2461 11.3518 11.9697 11.7309L12.2817 11.8934C12.7128 12.1187 13.2587 11.8414 13.2587 11.3323V5.25339C13.2587 4.91327 12.9597 4.58831 12.6088 4.60348C12.26 4.62081 11.9632 4.89161 11.9632 5.25556Z"
              fill="#BABABA"
            />
          </svg>
        </span>
        <span className="text-sm text-lightGrey transition ease-in-out group-hover:text-white group-[.menu-item-has-children.active]:text-white"></span>
        <span className="absolute top-5 right-4 transition ease-in-out group-[.menu-item-has-children.active]:rotate-180">
          <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="group-hover:stroke-white group-[.menu-item-has-children.active]:stroke-white"
              d="M1 1L6 6L11 1"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </>
    ),
  },
  {
    id: 5,
    to: "./user",
    label: "User",
    permission: ["Tenant User", "Poxfy User", "Super User"],
    children: [
      {
        id: 1,
        to: "./user",
        label: "User",
      },
      {
        id: 1,
        to: "./user/role",
        label: "Role",
      },
    ],
    icon: (
      <>
        <span className="flex items-center justify-center w-10 mr-2 rounded-full aspect-square bg-veryLightGrey transition ease-in-out group-hover:bg-white group-[.menu-item-has-children.active]:bg-white">
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
              d="M19.6703 5.25562V17.2596C19.6703 17.8142 19.6573 18.3688 19.6703 18.9234V18.9472L20.3203 18.2973H1.45317C1.19753 18.2973 0.941899 18.293 0.686264 18.2973H0.653769L1.30369 18.9472V6.94323C1.30369 6.38864 1.31668 5.83404 1.30369 5.27945V5.25562L0.653769 5.90553H19.5209C19.7765 5.90553 20.0321 5.9077 20.2878 5.90553H20.3203C20.6604 5.90553 20.9853 5.60657 20.9702 5.25562C20.955 4.90249 20.6842 4.6057 20.3203 4.6057H1.45317C1.19753 4.6057 0.941899 4.60353 0.686264 4.6057H0.653769C0.302813 4.6057 0.00385136 4.90249 0.00385136 5.25562V17.2596C0.00385136 17.8142 -0.0048142 18.3688 0.00385136 18.9234V18.9472C0.00385136 19.2982 0.300647 19.5971 0.653769 19.5971H19.5209C19.7765 19.5971 20.0321 19.5993 20.2878 19.5971H20.3203C20.6712 19.5971 20.9702 19.3003 20.9702 18.9472V6.94323C20.9702 6.38864 20.9788 5.83404 20.9702 5.27945V5.25562C20.9702 4.91549 20.6712 4.59053 20.3203 4.6057C19.9671 4.62086 19.6703 4.89166 19.6703 5.25562Z"
              fill="#BABABA"
            />
            <path
              className="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
              d="M20.3184 4.60795H1.45133C1.19569 4.60795 0.940058 4.60362 0.684424 4.60795H0.651928C0.805742 4.97841 0.957389 5.34669 1.1112 5.71715C1.59864 5.19721 2.08824 4.67945 2.57568 4.15951C3.35342 3.33195 4.13115 2.50656 4.90889 1.679L5.44182 1.11357C5.288 1.17639 5.13636 1.24138 4.98254 1.30421H14.6338C15.0779 1.30421 15.5242 1.31721 15.9705 1.30421H15.9878C15.834 1.24138 15.6823 1.17639 15.5285 1.11357C16.016 1.6335 16.5056 2.15127 16.993 2.6712C17.7707 3.49876 18.5485 4.32416 19.3262 5.15172L19.8591 5.71715C20.0931 5.96628 20.5459 5.97062 20.7777 5.71715C21.0225 5.45068 21.0268 5.0629 20.7777 4.7986C20.3358 4.32849 19.8938 3.85838 19.4519 3.39044C18.7045 2.59538 17.9549 1.80031 17.2075 1.00525C16.9822 0.764778 16.7569 0.526475 16.5294 0.286006C16.3799 0.127859 16.2326 0.0130403 15.9835 0.00437477H5.314C5.15369 0.00437477 4.96521 -0.0151227 4.80707 0.0282051C4.54277 0.101862 4.33696 0.389992 4.15715 0.580635L2.06441 2.80335L0.283641 4.69461L0.186154 4.7986C0.004177 4.99141 -0.0521492 5.26437 0.0518376 5.50917C0.151492 5.74531 0.385462 5.90779 0.645429 5.90779H19.5125C19.7682 5.90779 20.0238 5.90996 20.2794 5.90779H20.3119C20.652 5.90779 20.977 5.60883 20.9618 5.25787C20.9532 4.90475 20.6824 4.60795 20.3184 4.60795Z"
              fill="#BABABA"
            />
            <path
              className="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
              d="M11.9632 5.25556V11.3345C12.2882 11.1481 12.6153 10.9597 12.9402 10.7734C12.208 10.3899 11.4779 10.0065 10.7457 9.623C10.6504 9.57318 10.5572 9.52118 10.4619 9.47352C10.2258 9.35437 10.0005 9.37387 9.77515 9.48869C9.72532 9.51252 9.67766 9.53852 9.63 9.56451C9.20539 9.78765 8.78077 10.0108 8.35399 10.2318C8.00954 10.4116 7.66725 10.5914 7.32279 10.7712C7.64775 10.9575 7.97488 11.146 8.29983 11.3323V5.25339L7.64992 5.90331H12.6131C12.9532 5.90331 13.2782 5.60435 13.263 5.25339C13.2479 4.90027 12.9771 4.60348 12.6131 4.60348H7.64992C7.29896 4.60348 7 4.90027 7 5.25339V11.3323C7 11.8414 7.5481 12.1187 7.97704 11.8934C8.70062 11.5143 9.42203 11.1351 10.1456 10.756L10.4576 10.5935H9.80114C10.5247 10.9727 11.2461 11.3518 11.9697 11.7309L12.2817 11.8934C12.7128 12.1187 13.2587 11.8414 13.2587 11.3323V5.25339C13.2587 4.91327 12.9597 4.58831 12.6088 4.60348C12.26 4.62081 11.9632 4.89161 11.9632 5.25556Z"
              fill="#BABABA"
            />
          </svg>
        </span>
        <span className="text-sm text-lightGrey transition ease-in-out group-hover:text-white group-[.menu-item-has-children.active]:text-white"></span>
        <span className="absolute top-5 right-4 transition ease-in-out group-[.menu-item-has-children.active]:rotate-180">
          <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="group-hover:stroke-white group-[.menu-item-has-children.active]:stroke-white"
              d="M1 1L6 6L11 1"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </>
    ),
  },
  {
    id: 5,
    to: "./outlet",
    label: "Outlet",
    permission: ["Tenant User", "Super User", "Poxfy User"],
    children: [
      {
        id: 1,
        to: "./outlet",
        label: "Outlet Management",
      },
      {
        id: 2,
        to: "./store",
        label: "Store Management",
      },
      {
        id: 3,
        to: "./loyality-point",
        label: "Loyality Point",
      },
      {
        id: 4,
        to: "./cash-register-outlet",
        label: "Cash Register",
      },
    ],
    icon: (
      <>
        <span className="flex items-center justify-center w-10 mr-2 rounded-full aspect-square bg-veryLightGrey transition ease-in-out group-hover:bg-white group-[.menu-item-has-children.active]:bg-white">
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
              d="M19.6703 5.25562V17.2596C19.6703 17.8142 19.6573 18.3688 19.6703 18.9234V18.9472L20.3203 18.2973H1.45317C1.19753 18.2973 0.941899 18.293 0.686264 18.2973H0.653769L1.30369 18.9472V6.94323C1.30369 6.38864 1.31668 5.83404 1.30369 5.27945V5.25562L0.653769 5.90553H19.5209C19.7765 5.90553 20.0321 5.9077 20.2878 5.90553H20.3203C20.6604 5.90553 20.9853 5.60657 20.9702 5.25562C20.955 4.90249 20.6842 4.6057 20.3203 4.6057H1.45317C1.19753 4.6057 0.941899 4.60353 0.686264 4.6057H0.653769C0.302813 4.6057 0.00385136 4.90249 0.00385136 5.25562V17.2596C0.00385136 17.8142 -0.0048142 18.3688 0.00385136 18.9234V18.9472C0.00385136 19.2982 0.300647 19.5971 0.653769 19.5971H19.5209C19.7765 19.5971 20.0321 19.5993 20.2878 19.5971H20.3203C20.6712 19.5971 20.9702 19.3003 20.9702 18.9472V6.94323C20.9702 6.38864 20.9788 5.83404 20.9702 5.27945V5.25562C20.9702 4.91549 20.6712 4.59053 20.3203 4.6057C19.9671 4.62086 19.6703 4.89166 19.6703 5.25562Z"
              fill="#BABABA"
            />
            <path
              className="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
              d="M20.3184 4.60795H1.45133C1.19569 4.60795 0.940058 4.60362 0.684424 4.60795H0.651928C0.805742 4.97841 0.957389 5.34669 1.1112 5.71715C1.59864 5.19721 2.08824 4.67945 2.57568 4.15951C3.35342 3.33195 4.13115 2.50656 4.90889 1.679L5.44182 1.11357C5.288 1.17639 5.13636 1.24138 4.98254 1.30421H14.6338C15.0779 1.30421 15.5242 1.31721 15.9705 1.30421H15.9878C15.834 1.24138 15.6823 1.17639 15.5285 1.11357C16.016 1.6335 16.5056 2.15127 16.993 2.6712C17.7707 3.49876 18.5485 4.32416 19.3262 5.15172L19.8591 5.71715C20.0931 5.96628 20.5459 5.97062 20.7777 5.71715C21.0225 5.45068 21.0268 5.0629 20.7777 4.7986C20.3358 4.32849 19.8938 3.85838 19.4519 3.39044C18.7045 2.59538 17.9549 1.80031 17.2075 1.00525C16.9822 0.764778 16.7569 0.526475 16.5294 0.286006C16.3799 0.127859 16.2326 0.0130403 15.9835 0.00437477H5.314C5.15369 0.00437477 4.96521 -0.0151227 4.80707 0.0282051C4.54277 0.101862 4.33696 0.389992 4.15715 0.580635L2.06441 2.80335L0.283641 4.69461L0.186154 4.7986C0.004177 4.99141 -0.0521492 5.26437 0.0518376 5.50917C0.151492 5.74531 0.385462 5.90779 0.645429 5.90779H19.5125C19.7682 5.90779 20.0238 5.90996 20.2794 5.90779H20.3119C20.652 5.90779 20.977 5.60883 20.9618 5.25787C20.9532 4.90475 20.6824 4.60795 20.3184 4.60795Z"
              fill="#BABABA"
            />
            <path
              className="group-hover:fill-redOrange group-[.menu-item-has-children.active]:fill-redOrange"
              d="M11.9632 5.25556V11.3345C12.2882 11.1481 12.6153 10.9597 12.9402 10.7734C12.208 10.3899 11.4779 10.0065 10.7457 9.623C10.6504 9.57318 10.5572 9.52118 10.4619 9.47352C10.2258 9.35437 10.0005 9.37387 9.77515 9.48869C9.72532 9.51252 9.67766 9.53852 9.63 9.56451C9.20539 9.78765 8.78077 10.0108 8.35399 10.2318C8.00954 10.4116 7.66725 10.5914 7.32279 10.7712C7.64775 10.9575 7.97488 11.146 8.29983 11.3323V5.25339L7.64992 5.90331H12.6131C12.9532 5.90331 13.2782 5.60435 13.263 5.25339C13.2479 4.90027 12.9771 4.60348 12.6131 4.60348H7.64992C7.29896 4.60348 7 4.90027 7 5.25339V11.3323C7 11.8414 7.5481 12.1187 7.97704 11.8934C8.70062 11.5143 9.42203 11.1351 10.1456 10.756L10.4576 10.5935H9.80114C10.5247 10.9727 11.2461 11.3518 11.9697 11.7309L12.2817 11.8934C12.7128 12.1187 13.2587 11.8414 13.2587 11.3323V5.25339C13.2587 4.91327 12.9597 4.58831 12.6088 4.60348C12.26 4.62081 11.9632 4.89161 11.9632 5.25556Z"
              fill="#BABABA"
            />
          </svg>
        </span>
        <span className="text-sm text-lightGrey transition ease-in-out group-hover:text-white group-[.menu-item-has-children.active]:text-white"></span>
        <span className="absolute top-5 right-4 transition ease-in-out group-[.menu-item-has-children.active]:rotate-180">
          <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="group-hover:stroke-white group-[.menu-item-has-children.active]:stroke-white"
              d="M1 1L6 6L11 1"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </>
    ),
  },
];
